import React, { useContext, useState, Fragment, useEffect } from "react";
import { FormContext } from "../../../Context/FormContext";

//styling
import { Typography, Radio, Row, Space, Input, Button, Divider, Select, InputNumber } from 'antd';
import { EditFilled, DeleteFilled, PlusOutlined, ConsoleSqlOutlined } from '@ant-design/icons';
const { TextArea } = Input;
const { Title, Text } = Typography;
const { Option } = Select;

interface IState {
    choices: any,
    text: string,
    field: string,
    id: string,
    question: string
    setItem?: (item: ItemState) => void,
    // item:any,
    //prevState?: () => void
}

interface ItemState {

}

const Question = (props: any) => {

    const [radioValue, setRadioValue] = useState()
    const [item, setItem] = useState<any | null>();
    const [choices, setChoices] = useState<any | null>([]);
    const [question, setQuestion] = useState<any | null>("");
    const [tag, setTag] = useState<any | null>("");
    const [field, setField] = useState<any | null>("");
    const [textSize, setTextSize] = useState<any | null>("3");

    const FormState = useContext(FormContext)

    useEffect(() => {
        console.log(props.item)
        setItem(props.item)
        setChoices(props.item.choice)
        setQuestion(props.item.question)
        setTag(props.item.tag)
        setField(props.item.field)
    }, [props.item])

    useEffect(() => {
        let items_:any
        if (props.tab.toLowerCase() === "internal") {
            items_ = FormState?.formItemsInternal
        }
        if (props.tab.toLowerCase() === "external") {
            items_ = FormState?.formItemsExternal
        }
        
        console.log(items_)
        if (item) {
            const itemArrayIndex = items_.findIndex((v: any, i: any) => item.id === v.id)
            items_[itemArrayIndex] = item
            if (props.tab.toLowerCase() === "internal") {
                FormState?.setFormItemsInternal(items_)
            }   
            if (props.tab.toLowerCase() === "external") {
                FormState?.setFormItemsExternal(items_)
            }
            
        }

    }, [item]) //triggers on items change to update context of internal items

    function addOption() {
        if (item) {
            const choicesNew = [{ option: "", value: "" }]
            const choices = item.choices
            choices.push(choicesNew)
            setItem({
                ...item, choices
            })
        }
    }

    function deleteRadio(index: any) {
        if (item) {
            const choices = item.choices.filter((value: any, newItemIndex: any) => newItemIndex !== index)
            setItem({
                ...item, choices
            })
        }
    }

    const onChangeChoice = (e: any, index: number) => {
        console.log(e.target.value, index)
        if (item) {
            const { choices } = item
            choices[index] = { option: e.target.value, value: choices[index].value }
            setItem({
                ...item, choices
            })
        }
    }

    const onChangeChoiceValue = (e: any, index: number) => {
        console.log(e.target.value, index)
        if (item) {
            const { choices } = item
            choices[index] = { value: e.target.value, option: choices[index].option }
            setItem({
                ...item, choices
            })
        }
    }

    const onChangeRankingChoice = (e: any, index: number) => {
        console.log(e.target.value, index)
        if (item) {
            const { choices } = item
            choices[index] = { option: e.target.value, value: "" }
            setItem({
                ...item, choices
            })
        }
    }

    const onChangeRankingChoiceValue = (e: any, index: number) => {
        console.log(e.target.value, index)
        if (item) {
            const { choices } = item
            choices[index] = { value: e.target.value, option: choices[index].option }
            setItem({
                ...item, choices
            })
        }
    }

    const onChangeQuestion = (e: any) => {
        if (item) {
            setQuestion(e.target.value)
            //newFormState?.setEditQuestion(e.target.value)
            const question = e.target.value
            setItem({
                ...item, question
            })
        }
    }

    const onChangeHeader = (e: any) => {
        const question = e.target.value
        setQuestion(question)
        setItem({
            ...item, question
        })
    }

    const onChangeSubject = (e: any) => {
        const question = e.target.value
        setQuestion(question)
        setItem({
            ...item, question
        })
    }

    const onChangeAspect = (e: any) => {
        const question = e.target.value
        setQuestion(question)
        setItem({
            ...item, question
        })
    }

    const onChangeHeaderSize = (e: any) => {
        const textSize = e
        setTextSize(textSize)
        setItem({
            ...item, textSize
        })
    }

    const onChangeParagraph = (e: any) => {
        const question = e.target.value
        setQuestion(question)
        setItem({
            ...item, question
        })
    }

    const onChangeTag = (e: any) => {
        const tag = e.target.value
        console.log(e.target.value)
        setTag(tag)
        setItem({
            ...item, tag
        })
    }

    let radioOptions = null;
    let rankingOptions = null;
    let header = null;

    console.log(item)

    if (item) {
        if (item.choices && item.text === "Multiple Choice") {
            radioOptions = item.choices.map((item: any, index: any) => {
                return  <Row style={{ alignItems: "center" }}>
                            <Radio key={"radio-" + index} value={item.option} disabled={true}>
                                <Input onChange={(e) => onChangeChoice(e, parseInt(index))} value={item.option} />
                            </Radio>
                            <Input value={item.value} onChange={(e) => onChangeChoiceValue(e, parseInt(index))} style={{width:60}} min={1} max={99} />
                            <DeleteFilled onClick={() => deleteRadio(index)} style={{ fontSize: 16, marginLeft: 10 }} />
                        </Row>
            })
        }
        if ((item.choices && item.text === "Ranking") || item.choices && item.text === "RankingInternal") {
            rankingOptions = item.choices.map((item: any, index: any) => {
                return  <Row style={{ alignItems: "center" }}>
                            <Input style={{width: 200}} onChange={(e) => onChangeRankingChoice(e, parseInt(index))} value={item.option} />
                            <Input style={{width: 60}} onChange={(e) => onChangeRankingChoiceValue(e, parseInt(index))} value={item.value} />
                            <DeleteFilled onClick={() => deleteRadio(index)} style={{ fontSize: 16, marginLeft:10 }} />
                        </Row>
            })
        }
    }

    return (

        <Fragment>
            {props.item.text === "Multiple Choice" ?
                <Fragment>
                    <Space direction="vertical" style={{width: "100%"}}>
                        <Row>
                            <Input onChange={(e: any) => onChangeQuestion(e)} placeholder="Question" value={question} />
                        </Row>

                        <Radio.Group>
                            <Space direction="vertical">
                                {radioOptions}
                            </Space>
                        </Radio.Group>
                        <Button onClick={addOption}><PlusOutlined />Add Option</Button>
                        <Divider/>
                        <Row><Title level={5}>Tag:</Title></Row>
                        <Row>
                            <Radio.Group onChange={(e:any) => onChangeTag(e)} value={tag} buttonStyle="solid">
                                <Radio.Button className="environment" value="Environment">Environment</Radio.Button>
                                <Radio.Button className="social" value="Social">Social</Radio.Button>
                                <Radio.Button className="governance" value="Governance">Governance</Radio.Button>
                            </Radio.Group>
                        </Row>
                    </Space>
                </Fragment>
                : ""}
            {props.item.text === "Heading" ?
                <Fragment>
                    <Row>
                        <Input value={question} placeholder="Heading" onChange={(e) => onChangeHeader(e)} />
                    </Row>
                    <Divider/>
                    <Row><Title level={5}>Size:</Title></Row>
                    <Row>
                        <Select value={textSize} onChange={onChangeHeaderSize}>
                            <Option value="1">1</Option>
                            <Option value="2">2</Option>
                            <Option value="3">3</Option>
                            <Option value="4">4</Option>
                            <Option value="5">5</Option>
                            <Option value="6">6</Option>
                        </Select>
                    </Row>
                </Fragment>
                : ""}
            {props.item.text === "Subject" ?
                <Fragment>
                    <Row>
                        <Input value={question} placeholder="Subject" onChange={(e) => onChangeSubject(e)} />
                    </Row>
                </Fragment>
                : ""}
            {props.item.text === "Aspect" ?
                <Fragment>
                    <Row>
                        <Input value={question} placeholder="Aspect" onChange={(e) => onChangeAspect(e)} />
                    </Row>
                </Fragment>
                : ""}
            {props.item.text === "Paragraph" ?
                <Fragment>
                    <Row>
                        <TextArea value={question} rows={18} onChange={(e) => onChangeParagraph(e)} />
                    </Row>
                </Fragment>
                : ""}
            {props.item.text === "Longform" ?
                <Fragment>
                    <Row>
                        <TextArea rows={2} value={question} placeholder="Question" onChange={(e) => onChangeQuestion(e)} />
                    </Row>
                </Fragment>
                : ""}
            {(props.item.text === "Ranking" || props.item.text === "RankingInternal") ?
                <Fragment>
                    <Row>
                        <TextArea rows={5} value={question} placeholder="Question" onChange={(e) => onChangeQuestion(e)} />
                    </Row>
                    <Divider/>
                    <Space direction="vertical" style={{width: '100%'}}>
                        {rankingOptions}
                    </Space>
                    <Row>
                        <Button style={{marginTop:10}} onClick={addOption}><PlusOutlined />Add Ranking</Button>
                    </Row>
                    <Divider/>
                    <Row><Title level={5}>Tag:</Title></Row>
                    {props.item.text === "Ranking" ? 
                        <Row>
                            <Radio.Group onChange={(e:any) => onChangeTag(e)} value={tag} buttonStyle="solid">
                                <Radio.Button className="environment" value="Environment">Environment</Radio.Button>
                                <Radio.Button className="social" value="Social">Social</Radio.Button>
                                <Radio.Button className="governance" value="Governance">Governance</Radio.Button>
                            </Radio.Group>
                        </Row>
                    : ""}
                    {props.item.text === "RankingInternal" ? 
                        <Row>
                            <Input value={tag} onChange={(e:any) => onChangeTag(e)} />
                        </Row>
                    : ""}
                </Fragment>
                : ""}
        </Fragment>
    )
}

export default Question;