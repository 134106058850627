import {Fragment} from 'react';
import {Link, withRouter} from 'react-router-dom';

import Edit from './EditQuestionnaire'
import New from './NewQuestionnaire'
import Preview from './PreviewForm'

const FormController = (props:any) => {
    //control route for display form (edit or create)
    let component = null;

    switch(props.match.params.filter1) {
        case "create":
            component = <New/>;
            break;
        case "edit":
            component = <Edit form_id={props.match.params.filter2} />;
            break;
        case "preview":
            component = <Preview form_id={props.match.params.filter2} apiUrl="/api/form/"/>
            break;
    }
    
    return (
        <Fragment>
            {component}
        </Fragment>

    )
}

export default withRouter(FormController);