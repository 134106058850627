import { Fragment, useState, useContext, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { materialityAPI, hostname } from '../../Components/Helpers';
import { UserContext } from "../../Context/UserContext";
import { StakeholderTypes, StakeholderTypeOptions } from '../../constants';

//styling
import { Layout, Typography, Input, Row, Col, Divider, Button, message, Form, Table, Modal, Tabs, Select, Upload } from 'antd';
import { ColumnsType } from 'antd/es/table'
import { SaveOutlined, ReloadOutlined, UploadOutlined } from '@ant-design/icons'
import ListItemForm, { ListItemFormItems } from './ListItemForm';
const { Option } = Select
const { Text } = Typography

enum ListItemKeyNames {
    FirstName = "First Name",
    LastName = "Last Name",
    Email = "Email",
    PhoneNumber = "Phone Number",
    StakeholderType = "Type of Stakeholder"
}
interface IList {
    key: number;
    name: string;
    email: string;
    _id: string;
}

const IndividualList = (props: any) => {
    //control route for display form (edit or create)
    const [form] = Form.useForm()
    const [editForm] = Form.useForm();
    const [list, setList] = useState<any | null>([])

    const [loadListModalVisible, setLoadListModalVisible] = useState<boolean>(false)
    const [saveLoading, setSaveLoading] = useState<boolean>(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
    const [editModalVisible, setEditModalVisible] = useState<boolean>(false)
    const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false)
    const [errorLoading, setErrorLoading] = useState<boolean>(false)
    const [listItemName, setListItemName] = useState<any>("")
    const [listItemIndex, setListItemIndex] = useState<any>("")
    const [showError, setError] = useState(false)
    const [listLoaded, setListLoaded] = useState(false)
    const [unsaved, setUnsaved] = useState(false)
    const [file, setFile] = useState<any>({})
    const [csvFile, setCSVFile] = useState<any>({})
    const [currentEditItem, setEditCurrentItem] = useState<any | null>({})
    // const NewListState = useContext(NewListContext)
    const UserState = useContext(UserContext)

    const [types, setTypes] = useState<any | null>(StakeholderTypes)

    const info = (msg: any) => {
        message.info(msg)
    };
    const errorMessage = (msg: any) => {
        message.error(msg)
    };

    useEffect(() => {
        console.log(props.id)

        const getList = async (id: string) => {
            try {
                const res = await materialityAPI.get('/api/list/' + id)
                const { data } = res.data.row
                if (data) {
                    setList(data)
                }
                console.log(res)
            }
            catch (err) {
                console.log(err)
            }
        }
        if (props.id) {
            getList(props.id.id);
        }
    }, [props.id])


    const handleSaveList = async () => {
        setSaveLoading(true)
        console.log(list)
        let payload = {
            owner: UserState?.user._id,
            data: list
        }
        try {
            const res = await materialityAPI.put('/api/list/' + props.id.id, payload)
            setSaveLoading(false)
            info("List saved.")
            setUnsaved(false)
        }
        catch (error) {
            console.log(error)
            setSaveLoading(false)
            errorMessage("Error saving list.")
            //setErrorMessage(error.response.statusText + '. ' + error.response.data)
        }
    }

    const handleAddToList = async (item: any) => {

        item["Stakeholder Definition"] = props.string
        try {
            const items_ = [...list, item]
            setList([...list, item])
            info("Member added to list.")
        }
        catch (error) {
            errorMessage("Error adding member.")
        }
    }

    const handleDelete = (record: any, index: any) => {
        setDeleteModalVisible(!deleteModalVisible)
        setListItemName(record.email)
        setListItemIndex(index)
    }

    const handleEdit = (record: any, index: any) => {       
        setEditCurrentItem(record);
        setEditModalVisible(true);
    }


    const deleteListItem = async () => {
        try {
            const filteredList = list.filter((element: any, i: any) => i !== listItemIndex)
            setList(filteredList)
            setDeleteModalVisible(!deleteModalVisible)
            info("Member deleted.")
            let payload = {
                owner: UserState?.user._id,
                data: filteredList
            }
            console.log(UserState?.user._id);
            console.log(filteredList);
            const res = await materialityAPI.put('/api/list/' + props.id.id, payload)
            setSaveLoading(false)
            info("List saved.")
            setUnsaved(false)

        }
        catch (error) {
            errorMessage("Error deleting member.")
        }
    }


    const uploadCSV = async () => {

        if (Object.keys(csvFile).length > 0) {
            let split = csvFile.split(/\r\n|\n/)
            let i = 0
            let csvObj = []
            // check headers first, must be correct to proceed
            let headers = split[0].split(',')
            if (headers[0] === "Type of Stakeholder" && headers[1] === "First Name" && headers[2] === "Last Name" && headers[3] === "Email" && headers[4] === "Phone Number") {
                for (let x of split) {
                    let clean_x = x.replace(/(\r\n|\n|\r)/gm, "");
                    let line = {
                        "Type of Stakeholder": "",
                        "First Name": "",
                        "Last Name": "",
                        "Email": "",
                        "Phone Number": ""
                    }
                    if (i > 0) {
                        let tmp = clean_x.split(',')
                        line["Type of Stakeholder"] = tmp[0]
                        line["First Name"] = tmp[1]
                        line["Last Name"] = tmp[2]
                        line["Email"] = tmp[3]
                        line["Phone Number"] = tmp[4]

                        if (line["Email"] && line["Email"] !== '') {
                            csvObj.push(line)
                        }
                    }
                    i++
                }
                let payload = {
                    owner: UserState?.user._id,
                    data: csvObj
                }
                try {
                    await materialityAPI.put('/api/list/' + props.id.id, payload)
                    const reload = await materialityAPI.get('/api/list/' + props.id.id)
                    const { data } = reload.data.row
                    if (data) {
                        setList(data)
                    }
                    setUploadModalVisible(!uploadModalVisible)
                    setSaveLoading(false)
                    info("CSV loaded into list.")
                    setUnsaved(false)
                }
                catch (error) {
                    setSaveLoading(false)
                    errorMessage("Error saving list.")
                }
            }
            else {
                setSaveLoading(false)
                errorMessage("CSV headers don't match template.")
            }
        }
        else {
            setUploadModalVisible(!uploadModalVisible)
        }


    }

    const uploadprops = {
        maxCount: 1,
        accept: '.csv',
        beforeUpload: async (file: any) => {
            console.log(file)
            let { name } = file
            let nameSplit = name.split(".")
            const lastItem = nameSplit.pop()
            console.log(lastItem)
            if (lastItem === "csv") {
                setFile(file)
                let formData = new FormData();
                formData.append('file', file);
                const res = await materialityAPI.post('/api/list/file', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                const { path } = res.data
                const csvFile = await materialityAPI.get('/api/' + path)
                setCSVFile(csvFile.data)
            }
            else {
                // not a csv file
                errorMessage("File does not match .csv format")
            }

            return false
        }
    };

    const columns: ColumnsType<IList> = [

        {
            title: 'First Name',
            dataIndex: 'First Name',
            key: 'firstname',
            filterMultiple: false,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Last Name',
            dataIndex: 'Last Name',
            key: 'lastname',
            filterMultiple: false,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Email',
            dataIndex: 'Email',
            key: 'date',
        },
        {
            title: 'Phone Number',
            dataIndex: 'Phone Number',
            key: 'phonenumber',
        },
        {
            title: 'Type of Stakeholder',
            dataIndex: 'Type of Stakeholder',
            key: 'type',
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            filterMultiple: false,
            render: (text, record, index) => <a href="#" style={{ color: "#1DA57A" }} onClick={() => handleEdit(record, index)}>Edit</a>
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            filterMultiple: false,
            render: (text, record, index) => <a href="#" style={{ color: "#1DA57A" }} onClick={() => handleDelete(record, index)}>Delete</a>
        },

    ];

    const handleEditListItem = (values: ListItemFormItems) => {
        setEditModalVisible(false);

        // Update source record
        currentEditItem[ListItemKeyNames.FirstName] = values.firstName;
        currentEditItem[ListItemKeyNames.LastName] = values.lastName;
        currentEditItem[ListItemKeyNames.Email] = values.email;
        currentEditItem[ListItemKeyNames.PhoneNumber] = values.phoneNumber;
        currentEditItem[ListItemKeyNames.StakeholderType] = values.stakeholderType;

        // Clear the current edit item to also force a rerender with the updated values
        setEditCurrentItem({});        
    };

    return (
        <Row>
            <Modal
                title={"Delete " + listItemName}

                visible={deleteModalVisible}
                onOk={() => deleteListItem()}
                onCancel={() => setDeleteModalVisible(!deleteModalVisible)}
            >
                <p>Are you sure you want to delete?</p>
            </Modal>
            <Modal
                title="Edit"
                visible={editModalVisible}
                onCancel={() => setEditModalVisible(!editModalVisible)}
                footer={[
                    <Button key="cancel" onClick={() => setEditModalVisible(false)}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => {
                            editForm
                                .validateFields()
                                .then((values) => {
                                    handleEditListItem(values);
                                })
                                .catch((info) => {
                                    console.log("Validate Failed:", info);
                                });
                        }}
                    >
                        Submit
                    </Button>
                ]}
            >
          
                <ListItemForm 
                    form={editForm}
                    firstName={currentEditItem[ListItemKeyNames.FirstName]}
                    lastName={currentEditItem[ListItemKeyNames.LastName]}
                    email={currentEditItem[ListItemKeyNames.Email]}
                    phoneNumber={currentEditItem[ListItemKeyNames.PhoneNumber]}
                    stakeholderType={currentEditItem[ListItemKeyNames.StakeholderType]}
                />

            </Modal>
            <Modal
                width="620px"
                title="Upload CSV"
                visible={uploadModalVisible}
                onOk={() => uploadCSV()}
                onCancel={() => setUploadModalVisible(!uploadModalVisible)}
            >
                <Text>Uploading a CSV requires the CSV file to be formatted as the following:</Text>
                <br />
                <br />
                <Text code>Type of Stakeholder,First Name,Last Name,Email,Phone Number<br /></Text>
                <Text code>Potential ESG Investor,Jerome,Iginla,jerome.iginla@gmail.com,4039999999<br /></Text>
                <Text code>...<br /></Text>
                <Text code>...<br /></Text>
                <Text code>...<br /></Text>
                <br />
                <Button href={hostname + "/api/uploads/template.csv"}>Download CSV Template</Button>
                <Divider />
                <Upload {...uploadprops}>
                    <Button
                        icon={<UploadOutlined />}
                    >
                        Click to Upload
                    </Button>
                </Upload>
            </Modal>
            <Col span={18}>
                <Table style={{ width: '90%' }} pagination={{ pageSize: 30 }} dataSource={list} columns={columns} />
            </Col>
            <Col style={{ borderLeft: '1px solid #f0f0f0', paddingLeft: 20 }} span={6}>
                <Button
                    className="actionBox save"
                    type="primary"
                    icon={<SaveOutlined />}
                    loading={saveLoading}
                    onClick={handleSaveList}
                >
                    Save {props.string}
                </Button>
                <Button
                    className="actionBox launch"
                    type="primary"
                    icon={<ReloadOutlined />}
                    style={{ width: 200, marginTop: 10 }}
                    onClick={() => setUploadModalVisible(!uploadModalVisible)}
                >
                    Import {props.string} CSV
                </Button>
                <Form
                    layout="vertical"
                    form={form}
                    style={{ maxWidth: "300px", marginTop: 10 }}
                    onFinish={handleAddToList}
                >
                    <Form.Item name="First Name" style={{ width: '100%', marginBottom: '5px' }} >
                        <Input placeholder="First Name" style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name="Last Name" style={{ width: '100%', marginBottom: '5px' }} >
                        <Input placeholder="Last Name" style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name="Email" style={{ width: '100%', marginBottom: '5px' }} rules={[{ type: 'email', required: true }]} >
                        <Input placeholder="Email" style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name="Phone Number" style={{ width: '100%', marginBottom: '5px' }}>
                        <Input placeholder="Phone Number" style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name="Type of Stakeholder" style={{ width: '100%', marginBottom: '5px' }}>
                        <Select placeholder="Type of Stakeholder">
                            {StakeholderTypeOptions}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" type="primary" style={{ marginTop: 5 }} block>Add to {props.string} List</Button>
                    </Form.Item>
                </Form>

            </Col>
        </Row>

    )
}

export default IndividualList;