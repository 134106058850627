import {useState} from 'react';
import logo from '../Images/greenworks_logo_trans.svg';
import { Layout, Typography, Form, Input, Button } from 'antd';

const { Footer } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;

const Footer_ = () => {
    //const userState = useContext(UserContext);
    const [form] = Form.useForm();
    const [errorMessage, setErrorMessage] = useState("")
    const [showError, setShowError] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const handleSubmit = async() => {
        console.log('handle')
    }
    return (
        
        <Footer>
            <img style={{width: 200, marginBottom:20}} src={logo}/>
        </Footer>
        
    )
}

export default Footer_;