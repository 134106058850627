import {Fragment} from 'react';
import {Link, withRouter} from 'react-router-dom';

// import Edit from './EditForm';
import New from './NewList';
import Edit from './EditList';

const Controller = (props:any) => {
    //control route for display form (edit or create)
    let component = null;

    switch(props.match.params.filter1) {
        case "create":
            component = <New />
            break;
        case "edit":
            component = <Edit list_id={props.match.params.filter2}/>
    }
    
    return (
        <Fragment>
            {component}
        </Fragment>

    )
}

export default withRouter(Controller);