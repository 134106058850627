import { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { materialityAPI } from '../../Components/Helpers';
import { UserContext } from "../../Context/UserContext";

//styling
import { Layout, Typography, Input, Row, Col, Divider, Button, message } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
const { Content} = Layout;
const { Title, Text} = Typography;

const NewTemplate = (props:any) => {

    const [templateName, setTemplateName] = useState<any>("")
    const [saveLoading, setSaveLoading] = useState<boolean>(false)
    const UserState = useContext(UserContext);


    const info = (msg:any) => {
        message.info(msg)
    };

    const errorMessage = (msg:any) => {
        message.error(msg)
    };

    const handleSaveForm = async () => {
        setSaveLoading(true)
        let payload = {
            owner: UserState?.user._id,
            name: templateName,
            type: "internal"
        };

        try {
            const res = await materialityAPI.post('/api/template', payload);            

            console.log(res)
            setSaveLoading(false)
            info("Template saved.")
            // redirect to edit page
            props.history.push("/template/edit/"+res.data.newFormTemplate._id)
        }
        catch(error) {
            console.log(error)
            setSaveLoading(false)
            errorMessage("Error saving template.")
            //setErrorMessage(error.response.statusText + '. ' + error.response.data)
        }
    }

    return (
        <Layout style={{height: "100vh"}}>
            <Content style={{paddingLeft: 40, paddingTop: 40}}>
                <Row style={{alignItems: "center"}}>
                    <Col span={8}>
                        <Text style={{paddingBottom: 10}}>Enter the name of the Template:</Text>
                        <Input style={{marginTop: 10}} value={templateName} onChange={(e)=> setTemplateName(e.target.value)} placeholder="Template Name" />
                    </Col>
                    <Col span={2}></Col>
                    <Col span={2}></Col>
                    <Col style={{borderLeft: '1px solid #f0f0f0', paddingLeft: 20}} span={12}>
                        <Button
                            className="actionBox save"
                            type="primary"
                            icon={<SaveOutlined />}
                            loading={saveLoading}
                            onClick={handleSaveForm}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
                <Divider/>  
                <Row>
                  <Col span={8}>

                  </Col>
                  <Col span={2}></Col>
                  <Col span={2}></Col>
                  <Col style={{borderLeft: '1px solid #f0f0f0', paddingLeft: 20}} span={12}>

                  </Col>
                </Row>
            </Content>
        </Layout>
    )
}

export default withRouter(NewTemplate);