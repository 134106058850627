import {useState, useEffect, Fragment, useContext} from 'react';
import { UserContext } from "../../../Context/UserContext"

//styling
import { UploadOutlined } from '@ant-design/icons'
import { Layout, Typography, Space,  Button, Input, Form, Select, Modal, Divider, message as antdMessage} from 'antd';
import { materialityAPI } from '../../../Components/Helpers'
import { StakeholderTypeOptions } from '../../../constants';
const { Footer } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select

const PreviewEmail = (props:any) => {

    const [types, setTypes] = useState([])
    const [type, setType] = useState<any>("")
    const [messages, setMessages] = useState<any>([])
    const [message, setMessage] = useState<any>("")
    const [messageBody, setMessageBody] = useState<any>("")
    const [subjectLine, setSubjectLine] = useState<any>("")
    const [form] = Form.useForm();
    const UserState = useContext(UserContext)
    const initialValues = {
        firstName: 'John',
        lastName: 'Doe'
    };
    useEffect(()=> {
        setTypes(props.types)
    }, [props.types])

    useEffect(()=> {
        if (props.previewData.messages) {
            setMessages(props.previewData.messages)
        }
        
        if (props.previewData.messageBody) {
            setMessageBody(props.previewData.messageBody)
        }

        if (props.previewData.subjectLine) {
            setSubjectLine(props.previewData.subjectLine)
        }
        
    }, [props.previewData])

    useEffect(()=> {
        console.log(type)
        if (type) {
            const messages_ = messages
            const index = messages_.findIndex((v:any) => v.type === type)
            console.log(index)
            if (index > -1) {
                setMessage(messages_[index].message)
            }
            else{
                setMessage("")
            }
        }
        
    }, [type])

    const [emailPreviewModal, setEmailPreviewModal] = useState<boolean>(false)

    const sendPreviewEmail = async (subjectLine: any, stakeholderMessages: any, messageBody: any, recipient: any) => {
        try {
            
            let payload = {
                subjectLine: subjectLine,
                messages: stakeholderMessages,
                messageBody: messageBody,
                recipient: recipient
            };
            const res = await materialityAPI.post('/api/campaignsingle/sendpreview', payload)
            setEmailPreviewModal(false);
            antdMessage.info("Test email sent");
            
        }
        catch(error) {
            console.log(error)
        }
    };



    return (
        <Fragment>
            <Modal
                title={`Send Test Email`}
                visible={emailPreviewModal}
                onOk={() => setEmailPreviewModal(!emailPreviewModal)}
                onCancel={()=> setEmailPreviewModal(!emailPreviewModal)}
                footer={[
                    <Button key="cancel" onClick={() => setEmailPreviewModal(false)}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => {                            
                            form
                                .validateFields()
                                .then(async (values) => {
                                    await sendPreviewEmail(subjectLine, messages, messageBody, values);
                                })
                                .catch((info) => {
                                    console.log("Validate Failed:", info);
                                });
                        }}
                    >
                        Send
                    </Button>
                ]}
            >
                <Text><em>Note: the email reflects the recent saved data.  Make sure to save to see the latest!</em></Text>
                <Divider/>
                <Fragment>
            <Form
                form={form}
                labelCol={{ span: 6 }}
                
                initialValues={{
                    ...initialValues
                }}>
                <Form.Item label="First Name" name="firstName" style={{ width: '100%', marginBottom: '5px' }} >
                    <Input placeholder="First Name" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="Last Name" name="lastName" style={{ width: '100%', marginBottom: '5px' }} >
                    <Input placeholder="Last Name" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="Email" name="email" style={{ width: '100%', marginBottom: '5px' }} rules={[{ type: 'email', required: true }]} >
                    <Input placeholder="Email" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="Stakeholder Type" name="stakeholderType" style={{ width: '100%', marginBottom: '5px' }}>
                    <Select placeholder="Type of Stakeholder">
                        {StakeholderTypeOptions}
                    </Select>
                </Form.Item>
            </Form>
        </Fragment>


            </Modal>
            <Space direction="vertical">

                <Button
                    className="actionBox launch"
                    type="primary"
                    icon={<UploadOutlined />}
                    onClick={()=>setEmailPreviewModal(!emailPreviewModal)}
                    style={{textTransform: 'capitalize'}}
                >
                    Send {props.type} Test Email
                </Button>
            </Space>
        </Fragment>
        
    )
}

export default PreviewEmail
