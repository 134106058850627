import { useState, useContext, useEffect } from 'react';
import {Link} from 'react-router-dom'
import { materialityAPI } from '../../Components/Helpers';
import { NewListContext } from "../../Context/NewListContext";
import { UserContext } from "../../Context/UserContext";
import IndividualList from "./IndividualList"

//styling
import { Layout, Typography, Input, Row, Col, Divider, Button, message, Form, Table, Modal, Tabs } from 'antd';
import { ColumnsType } from 'antd/es/table'
import { SaveOutlined } from '@ant-design/icons'
const { Content } = Layout
const { Text, Title } = Typography
const { TabPane } = Tabs

const ListEdit = (props: any) => {
    const [list, setList] = useState<any | null>([])
    const [listName, setListName] = useState<any>("")
    const [saveLoading, setSaveLoading] = useState<boolean>(false)
    const [errorLoading, setErrorLoading] = useState<boolean>(false)
    const [listLoaded, setListLoaded] = useState(false)
    const [unsaved, setUnsaved] = useState(false)
    const UserState = useContext(UserContext)

    const [internalId, setInternalId] = useState<any>("")
    const [externalId, setExternalId] = useState<any>("")

    console.log(props.list_id)

    useEffect(() => {   //fired on load, reload from context
        const getList = async (id: any) => {
            try {
                const res = await materialityAPI.get('/api/contactlist/' + id)
                const {lists, name} = res.data.row
                const internal_id = lists.find((v:any)=> v.type === "internal")
                const external_id = lists.find((v:any)=> v.type === "external")
                console.log(res)
                if (lists && name) {
                    setListName(name)
                    setInternalId(internal_id)
                    setExternalId(external_id)
                    setListLoaded(true)
                }
                else {
                    setErrorLoading(true)
                }
            }
            catch (err) {
                console.log(err)
            }
        }
        if (props.list_id) {
            getList(props.list_id);
        }

    }, []);

    useEffect(() => {
        if (listLoaded) {
            handleSaveList()
        }

    }, [list])

    useEffect(() => {
        // if (listLoaded) {
        //     setUnsaved(true)
        // }
    }, [listName])

    const info = (msg: any) => {
        message.info(msg)
    };

    const handleSaveList = async () => {
        setSaveLoading(true)
        
        let payload = {
            owner: UserState?.user._id,
            name: listName,
            data: list
        }
        try {
            await new Promise(resolve => setTimeout(resolve, 1100))
            const res = await materialityAPI.put('/api/list/' + props.list_id, payload)
            setSaveLoading(false)
            info("List saved.")
            setUnsaved(false)
        }
        catch (error) {
            console.log(error)
            setSaveLoading(false)
            info("Error saving list.")
            //setErrorMessage(error.response.statusText + '. ' + error.response.data)
        }
    }

    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Content style={{ padding: 40 }}>
                <Row style={{ alignItems: "center" }}>
                    <Col span={8}>
                        {/* <Input style={{ width: '60%' }} value={listName} onChange={(e) => setListName(e.target.value)} placeholder="List Name" /> */}
                        <Title style={{marginBottom: 0}} level={5}><Link style={{color: '#1DA57A'}} to="/contacts">Contacts</Link> &gt; {listName}</Title>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={2}></Col>
                    <Col style={{ borderLeft: '1px solid #f0f0f0', paddingLeft: 20 }} span={12}>
                    </Col>
                </Row>
                <Divider/>
                <Row>
                    <Tabs style={{ width: '100%' }} defaultActiveKey="internal">
                        <TabPane tab="Internal" key="internal">
                            <IndividualList
                                id={internalId}
                                string="Internal"
                            />
                        </TabPane>
                        <TabPane tab="External" key="external">
                            <IndividualList
                                id={externalId}
                                string="External"
                            />
                        </TabPane>
                    </Tabs>
                </Row>
            </Content>
            
        </Layout>
    )
}

export default ListEdit;