import React, { useState, createContext } from 'react';

interface IFormContext {
  
  formItemsExternal: any;
  setFormItemsExternal: (state: any) => void;
  formItemsInternal: any;
  setFormItemsInternal: (state: any) => void;
}

export const FormContext = createContext<IFormContext | null>(null);

export const FormProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [formItemsExternal, setFormItemsExternal] = useState([])
  const [formItemsInternal, setFormItemsInternal] = useState([])

  return (
    <FormContext.Provider value={{ 
        formItemsExternal,
        setFormItemsExternal,
        formItemsInternal,
        setFormItemsInternal
    }}>
      {children}
    </FormContext.Provider>
  );
};