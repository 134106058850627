import React, { useState, createContext } from 'react';

  interface UserInterface {
	email: string;
	role: string;
	_id: string;
	firstName: string;
	lastName: string;
	company:string;
	token:string;
  }
  
  interface IUserContext {
	user: UserInterface;
	setUser: (state: UserInterface) => void;
	authenticated: boolean;
	setAuthenticated: (state: boolean) => void;
  }

  export const UserContext = createContext<IUserContext | null>(null);

  export const UserProvider: React.FC<React.ReactNode> = ({ children }) => {
	const [user, setUser] = useState({ 
										email: "", 
										role: "",
										_id: "",
										firstName: "",
										lastName: "",
										company: "",
										token: ""
									 });
	const [authenticated, setAuthenticated] = useState(false);
  
	return (
	  <UserContext.Provider value={{ user, setUser, authenticated, setAuthenticated }}>
		{children}
	  </UserContext.Provider>
	);
  };