import { useState, useContext} from 'react';
import {withRouter} from 'react-router-dom';
import { Row, Layout, Button, Input, Form, Typography, Divider} from 'antd';
import { materialityAPI, loadToken } from '../../../Components/Helpers'
//import { OmitProps } from 'antd/lib/transfer/ListBody';
const { Content } = Layout;  
const { Text, Title } = Typography;
const { TextArea } = Input;

const ContactForm = (props:any)=> {

    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState("")
    const [disableButton, setDisableButton] = useState(false)

    const handleSubmit =async(values:any) => {
        setLoading(true)
        setShowMessage(false)
        if (!values.message) {
            values.message = ""
        }
        try {
            await materialityAPI.post('/publicapi/feedback/mail', values)
            setMessage("Thank you.  Your message has been submitted.")
            setShowMessage(true)
            setDisableButton(true)
            setLoading(false)
        }
        catch(error) {
            console.log(error)
            setMessage("There has been an error.  Please try again later.")
            setShowMessage(true)
            setLoading(false)
        }


    }
    return (
        <Row>
            <Title level={4}>Contact GreenWorks</Title>

            <Divider />

            <Form
                layout="vertical"
                form={form}
                style={{width: '100%', maxWidth: "100%"}}
                onFinish={handleSubmit}
            >
                <Form.Item name="name" style={{width: '100%'}} rules={[{ required: true }]} >
                    <Input size="large" placeholder="Name" style={{ width: '100%'}}/>
                </Form.Item>
                <Form.Item name="email" style={{width: '100%'}} rules={[{ required: true, type: 'email' }]} >
                    <Input size="large" placeholder="Email" style={{ width: '100%'}}/>
                </Form.Item>
                <Form.Item name="message" style={{width: '100%'}} >
                    <TextArea rows={6} style={{width: '100%'}}/>
                </Form.Item>

                <Form.Item>
                    <Button disabled={disableButton} loading={loading} size="large" htmlType="submit" type="primary" style={{marginTop: 5}} block>Send a Message</Button>
                </Form.Item>
                {showMessage ? 
                    <Text>{message}</Text>
                : ""}
            </Form>
        </Row>
    )
}

export default ContactForm