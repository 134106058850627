import axios from 'axios';

console.log(process.env.REACT_APP_STAGE)

const materialityAPI  = process.env.REACT_APP_STAGE === "dev" ? axios.create({
    baseURL: "http://localhost:5000"
}) : axios.create({
    baseURL: "//materiality.greenworksep.com"
});


const hostname = process.env.REACT_APP_STAGE === "dev" ? "http://localhost:5000" : "https://materiality.greenworksep.com"
const AUTH_TOKEN = localStorage.getItem("materiality.Token");
materialityAPI.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;

const loadToken = () => {
    const AUTH_TOKEN = localStorage.getItem("materiality.Token");
    materialityAPI.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;
}

export { materialityAPI, loadToken, hostname };