import {Fragment, useEffect, useState} from 'react';

//styling
import { Typography, Radio, Space, Row, Divider, Form } from 'antd';
const { Title, Text } = Typography;

const MultipleChoice = (props:any) => {
    
    const [choices, setChoices] = useState([])

    useEffect(()=> {

        setChoices(props.choices)

    }, [])

    const radio = choices.map((item:any, index:any) => {
        return <Radio key={"radio-"+index} style={{paddingLeft:20}} value={item.value}>{item.option}</Radio>
    })
    
    return (
        <Fragment>
            <Row style={{marginBottom:20}}>
                <Text strong>{props.number}. {props.question}</Text>
            </Row>
            <Row>
            <Form.Item name={props.row} rules={[{ required: true, message: 'Please pick an item!' }]}>
                <Radio.Group >
                    <Space direction="vertical">
                        {radio}
                    </Space>
                </Radio.Group>
            </Form.Item>
            </Row>
            <Divider/>
        </Fragment>
    )
}

export default MultipleChoice