import {Link} from 'react-router-dom';
import { useEffect, useContext, useState, Fragment } from 'react';
import { UserContext } from "../Context/UserContext";
import { materialityAPI } from '../Components/Helpers';
import dayjs from 'dayjs';

import { Layout, Typography, Menu, Row, Col, Divider, Table, Modal, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { PlusOutlined } from '@ant-design/icons';
const { Content, Sider } = Layout;
const { Title } = Typography;

interface ICampaign {
    key: number;
    name: string;
    date: string;
    _id: string;
}

const Results = () => {
    const [campaigns, setCampaigns] = useState([])
    const UserState = useContext(UserContext)

    useEffect(() => {
        const getAllCampaigns = async(user:any) => {
            try {
                const res = await materialityAPI.get('/api/campaign/all/'+user._id)
                console.log(res)
                const data = res.data.row.reverse()

                setCampaigns(data)
            }
            catch(err) {
                console.log(err)
            }
        }
        const {user}:any = UserState;
        getAllCampaigns(user);
    }, []);


    const columns: ColumnsType<ICampaign> = [
        
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            filterMultiple: false,
            sortDirections: ['descend', 'ascend'],
            render: (text, record, index) => <Link style={{color: "#1DA57A"}} to={"/result/view/"+record._id}>{text}</Link>
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record, index) => dayjs(text).format('YYYY-MM-DD')
        }
        
    ];

    return (
        <Layout style={{minHeight: "80vh"}}>
            <Content style={{paddingLeft: 40, paddingTop: 40, paddingRight: 40}}>
                <Row style={{alignItems: "center"}}>
                    <Col span={20}>
                        <Title style={{marginBottom: 0}} level={5}>Results</Title>
                    </Col>
                    <Col span={4}>

                    </Col>
                </Row>
                <Divider/>  
                <Row>
                    <Table style={{width: '90%'}} pagination={{ pageSize: 10 }} dataSource={campaigns} columns={columns}/>
                </Row>
            </Content>
        </Layout>
    )
}

export default Results;