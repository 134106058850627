import { useState, useContext, useEffect } from 'react'
import {Link} from 'react-router-dom'
import DND from './Components/DND'
import { materialityAPI } from '../../Components/Helpers'
import { FormContext } from "../../Context/FormContext"
import { UserContext } from "../../Context/UserContext"
import AddItems from "./Components/AddItems/AddItems";
import AddItemsExternal from "./Components/AddItems/AddItemsExternal"
import AddItemsInternal from "./Components/AddItems/AddItemsInternal"
import { v4 as uuidv4 } from 'uuid';

//styling
import { Layout, Typography, Input, Row, Col, Divider, Button, message, Modal, Tabs, Select } from 'antd'
import { SaveOutlined, UploadOutlined, ReloadOutlined, ClearOutlined } from '@ant-design/icons'
const { Content} = Layout
const {Text, Title} = Typography
const { TabPane } = Tabs
const { Option } = Select

const FormTemplate = (props:any) => {
    const [formItem, setFormItem] = useState<any | null>({});
    const [formId, setFormId] = useState<any>("")
    const [saveLoading, setSaveLoading] = useState<boolean>(false)
    const [clearModalVisible, setClearModalVisible] = useState<boolean>(false)
    const [showError, setError] = useState(false)
    const [formLoaded, setFormLoaded] = useState(false)
    const [unsaved, setUnsaved] = useState(false)

    const FormState = useContext(FormContext)
    const UserState = useContext(UserContext)

    const { templateName } = props;

    useEffect(() => {   //fired on load, get form from db

        setFormId(props.id)
    }, [props.id]);

    const info = (msg:any) => {
        message.info(msg)
    };

    const errorMessage = (msg:any) => {
        message.error(msg)
    };

    const handleSaveFormItems = async (items:any) => {
        let payload:any = {
            owner: UserState?.user._id,
            name: templateName,
            data: []
        }

        if (props.string.toLowerCase() === "internal") {
            payload["data"] = FormState?.formItemsInternal
        }
        if (props.string.toLowerCase() === "external") {
            payload["data"] = FormState?.formItemsExternal
        }

        handleSaveForm(payload, "ITEMS")
    }

    const handleSaveForm = async(payload:any, type: any) => {

        try {
            setSaveLoading(true)
            const res = await materialityAPI.put('/api/template/'+formId, payload)
            setSaveLoading(false)
            info("Template saved.")

            setUnsaved(false)
        }
        catch(error) {
            console.log(error)
            setSaveLoading(false)
            errorMessage("Error saving template.")
            //setErrorMessage(error.response.statusText + '. ' + error.response.data)
        }
    }

    const clearTemplate = async() => {
        try {
            let payload = {
                data: []
            }
            setFormId("")
            setClearModalVisible(!clearModalVisible)
            await materialityAPI.put('/api/form/'+formId, payload)
            const newInternalForm = await materialityAPI.put('/api/template/'+formId, payload)
            setFormId(newInternalForm.data.row._id)
            info("Form cleared.")

        }
        catch(err) {
            console.log(err)
            errorMessage("Error clearing.")
        }
    }

    
    return (
        <Row>
            <Modal
                title="Clear Template"
                visible={clearModalVisible}
                onOk={() => clearTemplate()}
                onCancel={()=> setClearModalVisible(!clearModalVisible)}
            >
                <Text><em>Notice: this will delete the form contents and is irreversible!</em></Text>
            </Modal>
            <Col span={10}>
                <DND 
                    id={formItem.id}
                    text={formItem.text} 
                    choices={formItem.choices} 
                    field={formItem.field} 
                    question={formItem.question}
                    tag={formItem.tag}
                    form_id={formId}
                    tab={props.string}
                    
                    apiUrl="/api/template/"
                    saveForm={(items)=> handleSaveFormItems(items)}
                />
            </Col>
            <Col span={2}></Col>
            <Col span={6}>            
                <AddItems setFormItem={(e:any) => setFormItem(e)}/>
                <Divider/>
                
                <Button
                    className="actionBox save"
                    type="primary"
                    icon={<SaveOutlined />}
                    loading={saveLoading}
                    onClick={(e:any)=>handleSaveFormItems(e)}
                    style={{marginTop: 0}}
                >
                    Save Template
                </Button>
                <br/>
                <Button
                    className="actionBox launch"
                    type="primary"
                    icon={<UploadOutlined />}
                    style={{width: 200}}
                    href={"/template/preview/"+formId}
                    target="_blank"
                    >
                    Preview Template
                </Button>
                <br/>
                <Button
                    className="actionBox launch"
                    type="primary"
                    icon={<ClearOutlined />}
                    style={{width: 200, marginTop:10}}
                    onClick={()=> setClearModalVisible(!clearModalVisible)}
                    >
                    Clear Template
                </Button>

                {/* {props.string === "Internal" ? 
                    <AddItemsInternal setFormItem={(e:any) => setFormItem(e)}/>
                : ""}
                {props.string === "External" ? 
                    <AddItemsExternal setFormItem={(e:any) => setFormItem(e)}/>
                : ""} */}
            </Col>
        </Row>

    )
}

export default FormTemplate;