import { useState, useContext, useEffect, useRef } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { materialityAPI } from '../../Components/Helpers'
import { UserContext } from "../../Context/UserContext"
import PreviewEmail from "./Components/PreviewEmail"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Results from "../Result/Components/View"
import dayjs from "dayjs"

//styling
import { Layout, Collapse, Tooltip, Input, Row, Col, Divider, Button, message, Typography, Table, Modal, Select, Space, Switch, Tabs, Spin, Checkbox } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { CheckOutlined, PropertySafetyTwoTone, SaveOutlined, UploadOutlined } from '@ant-design/icons'
const { Content, Sider } = Layout
const { Panel } = Collapse
const { Text, Title } = Typography
const { Option } = Select
const { TextArea } = Input
const { TabPane } = Tabs

const CampaignSingle = (props: any) => {
    const [campaignResults, setCampaignResults] = useState<any | null>([])
    const [formResultsFormatted, setFormResultsFormatted] = useState<any | null>([])
    const [campaignName, setCampaignName] = useState<any>("")
    const [typeMessageModalVisible, setTypeMessageModalVisible] = useState<boolean>(false)
    const [saveLoading, setSaveLoading] = useState<boolean>(false)
    const [showError, setError] = useState(false)
    const [campaignSingleId, setCampaignSingleId] = useState<any | null>("")
    const [lists, setLists] = useState<any | null>([])
    const [forms, setForms] = useState<any | null>([])
    const [campaignForm, setCampaignForm] = useState<any | null>("")
    const [campaignList, setCampaignList] = useState<any | null>("")
    const [campaignFormId, setCampaignFormId] = useState<any | null>("")
    const [campaignListId, setCampaignListId] = useState<any | null>("")
    const [campaignStatus, setCampaignStatus] = useState<any | null>("")
    const [campaignStatusSwitch, setCampaignStatusSwitch] = useState<boolean>(false)
    const [changeMailingListModal, setChangeMailingListModal] = useState<boolean>(false)
    const [changeFormModal, setChangeFormModal] = useState<boolean>(false)
    const [subjectLine, setSubjectLine] = useState("")
    const [messageBody, setMessageBody] = useState("")
    const [launchCampaignModal, setLaunchCampaignModal] = useState<boolean>(false)
    const [stopCampaignModal, setStopCampaignModal] = useState<boolean>(false)
    
    const [sendOutEmails, setSendOutEmails] = useState<boolean>(false)
    const [types, setTypes] =  useState<any | null>([
        {type: "Current Investor", message: "", emails: ""},
        {type: "Potential ESG Investor", message: "", emails: ""},
        {type: "Customer", message: "", emails: ""},
        {type: "Client", message: "", emails: ""},
        {type: "Employee (Mid Mgmt)", message: "", emails: ""},
        {type: "Employee (Jnr Level)", message: "", emails: ""},
        {type: "Employee (Field/Factory)", message: "", emails: ""},
        {type: "Rating Agency", message: "", emails: ""},
        {type: "Community Member", message: "", emails: ""},
        {type: "Supplier/Vendor", message: "", emails: ""},
        {type: "Government", message: "", emails: ""},
        {type: "Regulator", message: "", emails: ""},
        {type: "NGO/Charity", message: "", emails: ""},
        {type: "Partners", message: "", emails: ""},
        {type: "Board Advisors", message: "", emails: ""},
        {type: "Board Director", message: "", emails: ""}
    ])
    
    const [previewData, setPreviewData] = useState<any | null>({})

    const UserState = useContext(UserContext)

    const info = (msg: any) => {
        message.info(msg)
    };

    const errorMessage = (msg:any) => {
        message.error(msg)
    };

    useEffect(()=> {
        const updateCampaignSingle = async(form_id:string) => {
            // get questionnaire, pull form id, add it to campaignsingle
           // console.log(form_id, props.type)
            const form = await materialityAPI.get('/api/questionnaire/'+form_id)
            //console.log(form)
            const {forms} = form.data.row

            const formSingle = forms.find((v:any)=> v.type === props.type)
            //update campaign single
            let payload = {
                form: formSingle.id
            }
            await materialityAPI.put('/api/campaignsingle/'+campaignSingleId, payload)
            //update campaign
            let payloadCampaign = {
                questionniare: form_id
            }
            await materialityAPI.put('/api/campaign/'+props.campaign_id, payloadCampaign)
            setCampaignFormId(formSingle.id)
        }
        if (campaignSingleId.length > 0) {
            updateCampaignSingle(props.campaign_form_id)
        }
        //console.log(campaignSingleId)
    }, [props.campaign_form_id])

    useEffect(()=> {
        const updateCampaignSingle = async(list_id:string) => {
            // get contact list, pull list id, add it to campaignsingle
            //console.log(list_id, props.type)
            const list = await materialityAPI.get('/api/contactlist/'+list_id)
            const {lists} = list.data.row

            const listSingle = lists.find((v:any)=> v.type === props.type)
            //console.log(listSingle)
            //update campaignsingle
            let payload = {
                list: listSingle.id
            }
            await materialityAPI.put('/api/campaignsingle/'+campaignSingleId, payload)
            //update campaign
            let payloadCampaign = {
                contactList: list_id
            }
            await materialityAPI.put('/api/campaign/'+props.campaign_id, payloadCampaign)
            const resList = await materialityAPI.get('/api/list/'+listSingle.id)
            const { data } = resList.data.row

            /**
             * Update Types with emails
             */

            let arrTypes:any = []
            for(let x in types) {
                let obj:any = types[x]
                let arr:any = []
                if(data) {
                    for (let y of data) {
                        if (y["Type of Stakeholder"] === types[x].type) {
                            arr.push(y["Email"])
                        }
                    }
                }
                obj['emails'] = arr.toString()
                arrTypes.push(obj)
            }

            setTypes(arrTypes)
            setCampaignListId(listSingle.id)
        }
        if (campaignSingleId.length > 0) {
            updateCampaignSingle(props.campaign_list_id)
        }
        //console.log(props.type, props.campaign_list_id)


    }, [props.campaign_list_id])

    useEffect(() => {   //fired on load, load from database
        //console.log(props.type)
        const getCampaign = async () => {
            try {

                const res = await materialityAPI.get('/api/campaign/'+props.campaign_id)
                const { campaigns } = res.data.row

                const campaign = campaigns.find((v:any)=> v.type.toLowerCase() === props.type.toLowerCase())
                const {id} = campaign
                const campaignSingle = await materialityAPI.get('/api/campaignsingle/'+id)
                const {form, list, messageBody, subjectLine, messages, campaignState} = campaignSingle.data.row

                setCampaignListId(list)
                setCampaignFormId(form)
                setPreviewData(
                    campaignSingle.data.row
                )
                //console.log('messsageBody', messageBody)
                if (messageBody) {
                    setMessageBody(messageBody)
                }
                else {

                }
                // const parser = new DOMParser()
                // const dom:any = parser.parseFromString(messageBody, "text/html");

                
                setCampaignStatus(campaignState)
                setSubjectLine(subjectLine)
                setCampaignSingleId(id)

                if (messages && messages.length > 0) {
                    setTypes(messages)
                }
                switch(campaignState) {
                    case "RUNNING":
                        //console.log(campaignState)
                        setCampaignStatusSwitch(true)
                        break
                    case "CLOSED":
                        setCampaignStatusSwitch(false)
                        break
                    case "ARCHIVED":
                        setCampaignStatusSwitch(false)
                        break
                }
            }
            catch(error) {
                console.log(error)
                errorMessage("Error loading campaign.")
            }
            
        }
        getCampaign()

    }, [props.type]);

    useEffect(()=> {

    }, [])

    /**
     * Handle saving the campaign
     */

    const handleSaveCampaign = async () => {
        setSaveLoading(true)
        let payload = {
            form: campaignFormId,
            list: campaignListId,
            name: campaignName,
            messageBody: messageBody,
            subjectLine: subjectLine,
            messages: types
        }

        try {
            await materialityAPI.put('/api/campaignsingle/'+campaignSingleId, payload)
            const res = await materialityAPI.get('/api/campaignsingle/'+campaignSingleId)
            setPreviewData(
                res.data.row
            )
            setSaveLoading(false)
            info("Campaign saved.")
        }
        catch (error) {
            console.log(error)
            setSaveLoading(false)
            errorMessage("Error saving campaign.")
        }
    }

    /**
     * Handle changing the campaign
     */
    const changeForm = async() => {
        let payload = {
            questionnaire: campaignFormId
        }
        const currentform = forms.find((v:any)=> v._id === campaignFormId)
        setCampaignForm(currentform)

        try {
            const res = await materialityAPI.put('/api/campaign/'+props.campaign_id, payload)
            setChangeFormModal(!changeFormModal)
            setSaveLoading(false)
            info("Campaign saved.")
        }
        catch(error) {
            console.log(error)
            setSaveLoading(false)
            errorMessage("Error saving campaign.")
        }
    }

    const changeMailingList = async() => { //update campaign
        let payload = {
            list: campaignListId
        }
        const currentlist = lists.find((v:any)=> v._id === campaignListId)
        setCampaignList(currentlist)

        try {
            const res = await materialityAPI.put('/api/campaignsingle/'+props.campaign_id, payload)
            setChangeMailingListModal(!changeMailingListModal)
            setSaveLoading(false)
            info("Campaign saved.")
        }
        catch(error) {
            console.log(error)
            setSaveLoading(false)
            errorMessage("Error saving campaign.")
        }
    }

    const handleResultsTab = async(key:string) => {
        // refresh results
        if (key === "2") {
            try {
                const res = await materialityAPI.get('/api/campaignsingle/'+props.campaign_id)
                const { campaignResults} = res.data.row
                setCampaignResults(campaignResults)
            }
            catch(err) {
                console.log(err)
            }
        }
    }

    const handleLaunch = async() => {
        try {
            console.log('launching', sendOutEmails)
            await handleSaveCampaign()

            let payload = {
                campaignState: "RUNNING",
                formLink: "/feedback",
            }

            const res = await materialityAPI.put('/api/campaignsingle/launch/'+campaignSingleId, payload)
            console.log(res)
            if (res.data.message) {
                errorMessage(res.data.message)
            }
            const {campaignResults} = res.data.row
            if (campaignResults && sendOutEmails) {
                // send mail
                const mailres = await materialityAPI.post('/api/campaignsingle/mail', res.data.row)
                console.log(mailres)
                info("Campaign launched.")
                setLaunchCampaignModal(!launchCampaignModal)
                setCampaignStatus(res.data.row.campaignState)
                setCampaignStatusSwitch(true)
            }
            else if (campaignResults && !sendOutEmails) {
                info("Campaign launched.")
                setLaunchCampaignModal(!launchCampaignModal)
                setCampaignStatus(res.data.row.campaignState)
                setCampaignStatusSwitch(true)
            }
            else {
                // do nothing
            }
        }
        catch(error) {
            console.log(error)
            errorMessage(error.response.data.message)
        }
        
    }

    const handleStop = async() => {
        try {
            
            let payload = {
                campaignState: "CLOSED",
            }
            const res = await materialityAPI.put('/api/campaignsingle/'+campaignSingleId, payload)
            console.log(res)
            if (res.data.row) {
                info("Campaign stopped.")
                setStopCampaignModal(!stopCampaignModal)
                setCampaignStatus(res.data.row.campaignState)
                setCampaignStatusSwitch(false)
            }
        }
        catch(error) {
            console.log(error)
        }
        
    }

    const handleTypeMessage = async(index:any, e:any) => {
        const messages_ = [...types]        //mutation
        messages_[index] = {"message": e.target.value, type: messages_[index].type}
        setTypes(messages_)
    }

    const listOptions = lists.map((item: any, index: any) => {
        return <Option key={item._id} value={item._id}>{item.name}</Option>
    })

    const formOptions = forms.map((item: any, index: any) => {
        return <Option key={item._id} value={item._id}>{item.name}</Option>
    })

    const typeButtons = types.map((item: any, index: any) => {
        return  <Panel header={`${item.type}`} key={index}>
                    <Tooltip title={item.emails}><TextArea rows={6} onChange={(e:any)=>handleTypeMessage(index, e)} value={item.message}></TextArea></Tooltip>
                </Panel>
    })

    return (
            
                
        <Row>
            
            <Modal
                title={"Launch Campaign"}
                visible={launchCampaignModal}
                onOk={() => handleLaunch()}
                onCancel={()=> setLaunchCampaignModal(!launchCampaignModal)}
            >
                <Text>Performing this action will launch the campaign.</Text>
                <br/>
                <br/>
                <Text>The questionnaire will go live and emails will be sent to the contacts in the mailing list attached to this campaign.</Text>
                <br/>
                <br/>
                <Checkbox
                    onChange={(e)=> setSendOutEmails(!sendOutEmails)}
                    value={sendOutEmails}
                >
                    Send emails (uncheck to launch campaign <em>without</em> sending emails)
                </Checkbox>
                <br/>
                <br/>
                <Text>Press <strong>Cancel</strong> to go back and review the form and mailing list.</Text>
                <br/>
                <br/>
                <Text>Press <strong>OK</strong> if you are ready to proceed.</Text>
            </Modal>
            <Modal
                title={"Stop Campaign"}
                visible={stopCampaignModal}
                onOk={() => handleStop()}
                onCancel={()=> setStopCampaignModal(!stopCampaignModal)}
            >
                <Text>Performing this action will <strong>STOP</strong> the campaign.  What does this mean?</Text>
                <br/>
                <br/>
                <Text>Users will no longer have access to forms.</Text>
                <br/>
                <br/>
                <Text>Press <strong>Cancel</strong> to go back.</Text>
                <br/>
                <br/>
                <Text>Press <strong>OK</strong> if you are ready to proceed.</Text>
            </Modal>
            <Col span={12}>
                <Space style={{width: "100%"}} size="large" direction="vertical">
        
                    <Row style={{border: '1px solid #ddd', width: '95%', padding: 20}}>
                        <Title style={{width: '100%', borderBottom: '1px solid #ddd', textTransform: 'capitalize'}} level={5}>{props.type} Subject Line</Title>
                        <Col span={18}>
                            <Input value={subjectLine} onChange={(e:any)=> setSubjectLine(e.target.value)} placeholder="Subject Line" />
                        </Col>
                    </Row>
                    <Row style={{border: '1px solid #ddd', width: '95%', padding: 20}}>
                        <Title style={{width: '100%', borderBottom: '1px solid #ddd'}} level={5}>Stakeholder Introduction</Title>
                        <Col span={24}>
                            <Collapse>
                                {typeButtons}
                            </Collapse>
                        </Col>
                    </Row>
                    <Row>
                        <ReactQuill style={{width: '95%', minHeight: '300px'}} theme="snow" value={messageBody} onChange={setMessageBody}/>
                    </Row>
                    
                </Space>
            </Col>

            <Col span={12} style={{ borderLeft: '1px solid #f0f0f0', paddingLeft: 20 }}>
                <Row style={{marginTop: 20, marginBottom: 20}}>
                    <Button
                        className="actionBox save"
                        type="primary"
                        icon={<SaveOutlined />}
                        loading={saveLoading}
                        onClick={handleSaveCampaign}
                        style={{textTransform: 'capitalize'}}
                    >
                        Save {props.type}
                    </Button>
                </Row>
                <Space direction="vertical" style={{width:'200px', backgroundColor: '#DDD', padding: 20, border: "1px solid #CCC", marginTop: 0}}>
                    
                    <Row>
                        <Text strong>Campaign Status</Text>
                    </Row>
                    <Row>
                        <Space>
                            {campaignStatusSwitch ? 
                                <Spin size="small" />
                            : ""}
                            
                            <Text code>{campaignStatus}</Text>
                            <Switch checked={campaignStatusSwitch} />
                        </Space>
                    </Row>
                </Space>
                <Row style={{marginTop: 20}}>
                    {campaignStatusSwitch ? 
                        <Button
                        className="actionBox launch"
                        type="primary"
                        icon={<UploadOutlined />}
                        onClick={()=>setStopCampaignModal(!stopCampaignModal)}
                        style={{textTransform: 'capitalize'}}
                        >
                        Stop {props.type} Campaign
                        </Button>
                    : 
                    <Button
                        className="actionBox launch"
                        type="primary"
                        icon={<UploadOutlined />}
                        onClick={()=>setLaunchCampaignModal(!launchCampaignModal)}
                        style={{textTransform: 'capitalize'}}
                    >
                        Launch {props.type} Campaign
                    </Button>
                    }
                </Row>
                <Divider/>
                <Row>
                    <PreviewEmail 
                        types={types}
                        previewData={previewData}
                        campaignId={props.campaign_id}
                        type={props.type}
                    />
                </Row>
                
            </Col>
        </Row>
                
    )
}

export default withRouter(CampaignSingle);