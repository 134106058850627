import { Link } from 'react-router-dom';
import { useEffect, useContext, useState, Fragment } from 'react';
import { UserContext } from "../Context/UserContext";
import { materialityAPI } from '../Components/Helpers';
import dayjs from 'dayjs';

import { Layout, Typography, Button, Row, Col, Form, Divider, Input, Table, Modal, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { PlusOutlined } from '@ant-design/icons';
const { Content, Sider } = Layout;
const { Title } = Typography;

interface IForm {
    key: number;
    name: string;
    date: string;
    _id: string;
}

const Templates = () => {
    const [templates, setTemplates] = useState([]);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [renameModalVisible, setRenameModalVisible] = useState(false);
    const [copyModalVisible, setCopyModalVisible] = useState(false);
    const [deleteKey, setDeleteKey] = useState("")
    const [deleteFormName, setDeleteFormName] = useState("")
    const [currentEditItem, setEditCurrentItem] = useState<any | null>({})
    const UserState = useContext(UserContext)
    const [renameForm] = Form.useForm()
    const [copyForm] = Form.useForm()
    const getAllTemplates = async (user: any) => {
        try {
            const res = await materialityAPI.get('/api/template/all/' + user._id);
            setTemplates(res.data.row)
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        const { user }: any = UserState;
        getAllTemplates(user);
    }, []);

    const renameFormInitialValues = { name: currentEditItem?.name };
    const copyFormInitialValues = { name: currentEditItem?.name + ' Copy' };
    useEffect(() => {
        renameForm.setFieldsValue(renameFormInitialValues);
        copyForm.setFieldsValue(copyFormInitialValues);
    })

    //Delete Module
    const handleDelete = (record: any) => {
        setDeleteModalVisible(!deleteModalVisible)
        setDeleteKey(record._id)
        setDeleteFormName(record.name)
    }
    const deleteForm = async () => {
        try {
            const res = await materialityAPI.delete('/api/template/' + deleteKey)

            const { user }: any = UserState;
            await getAllTemplates(user);

            setDeleteModalVisible(!deleteModalVisible)
            message.info("Template deleted.")
        }
        catch (error) {
            console.log(error)
            message.info("Error deleting template.")
            setDeleteModalVisible(!deleteModalVisible)
        }
    }

    const info = (msg: any) => {
        message.info(msg)
    };

    const columns: ColumnsType<IForm> = [

        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            filterMultiple: false,
            sortDirections: ['descend', 'ascend'],
            render: (text, record, index) => <Link style={{ color: "#1DA57A" }} to={"/template/edit/" + record._id}>{text}</Link>
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record, index) => dayjs(text).format('YYYY-MM-DD')
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            filterMultiple: false,
            width: '80px',
            render: (text, record, index) => <a href="#" style={{ color: "#1DA57A" }} onClick={() => onCopy(record, index)}>Copy</a>
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            filterMultiple: false,
            width: '100px',
            render: (text, record, index) => <a href="#" style={{ color: "#1DA57A" }} onClick={() => onRename(record, index)}>Rename</a>
        },

        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            filterMultiple: false,
            width: '100px',
            render: (text, record, index) => <a href="#" style={{ color: "#1DA57A" }} onClick={() => handleDelete(record)}>Delete</a>
        },

    ];

    const handleRenameTemplate = async (values: any) => {
        const id = currentEditItem._id;
        const name = values.name;
        console.log(id);
        try {
            const res = await materialityAPI.patch('/api/template/name/' + id, { name: name })

            const { user }: any = UserState;
            await getAllTemplates(user);

            setRenameModalVisible(false);
            message.info("Template renamed.")
        }
        catch (error) {
            console.log(error)
            message.info("Error renaming template.")
            setRenameModalVisible(false);
        }
    }

    const handleCopyTemplate = async (values: any) => {
        const id = currentEditItem._id;
        const name = values.name;
        try {
            const res = await materialityAPI.post('/api/template/copy/' + id, { name: name })

            const { user }: any = UserState;
            await getAllTemplates(user);

            setCopyModalVisible(false);
            message.info("Template Copied.")
        }
        catch (error) {
            console.log(error)
            message.info("Error copying template.")
            setCopyModalVisible(false);
        }
    }

    const onCopy = (record: any, index: any) => {
        setEditCurrentItem(record);
        copyForm.resetFields();
        setCopyModalVisible(true);
    }

    const onRename = (record: any, index: any) => {
        setEditCurrentItem(record);
        renameForm.resetFields();
        setRenameModalVisible(true);
    }

    return (
        <Fragment>
            <Modal
                title={"Delete " + deleteFormName}
                visible={deleteModalVisible}
                onOk={() => deleteForm()}
                onCancel={() => setDeleteModalVisible(!deleteModalVisible)}
            >
                <p>Are you sure you want to delete?</p>
            </Modal>
            <Modal
                title="Copy Template"
                visible={copyModalVisible}
                onCancel={() => setCopyModalVisible(false)}
                footer={[
                    <Button key="cancel" onClick={() => setCopyModalVisible(false)}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => {
                            copyForm
                                .validateFields()
                                .then((values) => {
                                    handleCopyTemplate(values);
                                })
                                .catch((info) => {
                                    console.log("Validate Failed:", info);
                                });
                        }}
                    >
                        Submit
                    </Button>
                ]}
            >

                <Form
                    form={copyForm}
                    labelCol={{ span: 6 }}
                    initialValues={{
                        ...copyFormInitialValues
                    }}>
                    <Form.Item label="Name" name="name" style={{ width: '100%', marginBottom: '5px' }} rules={[{ required: true }]} >
                        <Input placeholder="Name" style={{ width: '100%' }} />
                    </Form.Item>
                </Form>
            </Modal>
            
            <Modal
                title="Rename Template"
                visible={renameModalVisible}
                onCancel={() => setRenameModalVisible(false)}
                footer={[
                    <Button key="cancel" onClick={() => setRenameModalVisible(false)}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => {
                            renameForm
                                .validateFields()
                                .then((values) => {
                                    handleRenameTemplate(values);
                                })
                                .catch((info) => {
                                    console.log("Validate Failed:", info);
                                });
                        }}
                    >
                        Submit
                    </Button>
                ]}
            >

                <Form
                    form={renameForm}
                    labelCol={{ span: 6 }}

                    initialValues={{
                        ...renameFormInitialValues
                    }}>
                    <Form.Item label="Name" name="name" style={{ width: '100%', marginBottom: '5px' }} rules={[{ required: true }]} >
                        <Input placeholder="Name" style={{ width: '100%' }} />
                    </Form.Item>
                </Form>
            </Modal>

            <Layout style={{ minHeight: "80vh" }}>
                <Content style={{ paddingLeft: 40, paddingTop: 40 }}>
                    <Row style={{ alignItems: "center" }}>
                        <Col span={20}>
                            <Title style={{ marginBottom: 0 }} level={5}>Templates</Title>
                        </Col>
                        <Col span={4}>
                            <div className="actionBox">
                                <PlusOutlined style={{ fontSize: '14px' }} />
                                <Title className="actionBox__title" level={5}>New Template</Title>
                                <Link to="/template/create/new"></Link>
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Table style={{ width: '90%' }} pagination={{ pageSize: 10 }} dataSource={templates} columns={columns} />
                    </Row>
                </Content>
            </Layout>
        </Fragment>
    )
}

export default Templates;