import { Fragment } from 'react';
import { StakeholderTypes } from '../../constants';

//styling
import { Input, Form, Select } from 'antd';

import { FormInstance } from 'antd/lib/form';
import { useEffect } from 'react';

const { Option } = Select

export interface ListItemFormItems {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    stakeholderType: string;
}
interface ListItemProps extends ListItemFormItems {
    form: FormInstance<any>;
}



const ListItemForm = (props: ListItemProps) => {

    const stakeholderTypeOptions = StakeholderTypes.map((item: any, index: any) => {
        return <Option value={item}>{item}</Option>
    });
    const { form, ...initialValues } = props;
    useEffect(() => {
        form.setFieldsValue(initialValues);        
    })

    return (
        <Fragment>
            <Form
                form={form}
                labelCol={{ span: 6 }}
                
                initialValues={{
                    ...initialValues
                }}>
                <Form.Item label="First Name" name="firstName" style={{ width: '100%', marginBottom: '5px' }} >
                    <Input placeholder="First Name" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="Last Name" name="lastName" style={{ width: '100%', marginBottom: '5px' }} >

                    <Input placeholder="Last Name" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="Email" name="email" style={{ width: '100%', marginBottom: '5px' }} rules={[{ type: 'email', required: true }]} >
                    <Input placeholder="Email" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="Phone Number" name="phoneNumber" style={{ width: '100%', marginBottom: '5px' }}>
                    <Input placeholder="Phone Number" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="Stakeholder Type" name="stakeholderType" style={{ width: '100%', marginBottom: '5px' }}>
                    <Select placeholder="Type of Stakeholder">
                        {stakeholderTypeOptions}
                    </Select>
                </Form.Item>

            </Form>
        </Fragment>
    );

};

export default ListItemForm;