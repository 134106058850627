import { Link } from 'react-router-dom';
import { hostname } from '../Components/Helpers'
import logo from '../Images/greenworks_logo_black.svg'
import { Layout, Typography, Row, Input, Button } from 'antd'
import { LinkOutlined } from '@ant-design/icons'

const { Title, Text } = Typography
const { TextArea } = Input

const GreenWorksTag = () => {

    return (
        
        <div style={{marginTop: 40, marginBottom: 60, textAlign: 'center'}}>
            <img style={{width: 200, textAlign: 'center', display: 'block', margin: '0 auto', marginBottom: 20}} src={logo}/>
            <Link target="_blank" style={{fontSize: 20, fontWeight: 300, margin: '0 auto'}} to="/contact-us"><LinkOutlined /> Learn how GreenWorks can help with your ESG solution.</Link>
        </div>

    )
}

export default GreenWorksTag