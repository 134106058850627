import { Fragment, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { materialityAPI } from '../../Components/Helpers'

import Heading from '../Form/Components/Preview/PreviewHeading'
import Paragraph from '../Form/Components/Preview/PreviewParagraph'
import MultipleChoice from './Components/MultipleChoice'
import Ranking from './Components/Ranking'
import Longform from './Components/Longform'
import GreenWorksTag from '../../Components/GreenWorksTag'

//styles
import { Result, Button, Form, Alert } from 'antd';

const FeedbackForm = (props: any) => {
    //control route for display form (edit or create)
    let component = null;
    console.log(props, 'feedback form')

    const [error, setError] = useState(false)
    const [fields, setFields] = useState<any | null>([])
    const [formData, setData] = useState<any | null>([])
    const [formError, setFormError] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [form] = Form.useForm()

    useEffect(() => {

        const getFormData = async (id: string) => {
            try {
                const resForm = await materialityAPI.get('/publicapi/feedback/form/' + id)
                const { data } = resForm.data.row
                console.log(data)
                setData(data)
                setError(false)

            }
            catch (error) {
                setError(true)
            }

        }
        if (props.formId) {
            getFormData(props.formId)
        }
        else {
            //show error
            setError(true)
        }

    }, [])

    const handleSubmit = async () => {
        console.log('submitted!')
        setFormError(false)
        setSubmitLoading(true)
        try {
            const values = await form.validateFields();
            console.log('Success:', values);
            const res = await materialityAPI.put('/publicapi/feedback/submitform/'+props.match.params.campaignId+'/'+props.match.params.hash, values)
            //console.log(res)
            //on success, switch to feedbackcomplete
            props.feedbackComplete()
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
            setFormError(true)
            setSubmitLoading(false)
        }

    }

    let count = 0;

    console.log(formData)

    const formRow = formData.map((item: any, key: any) => {
        if (item.text === "Multiple Choice" || item.text === "RankingInternal" || item.text === "Ranking" || item.text === "Longform") { count++ }
        switch (item.text) {
            case "Multiple Choice":
                return <MultipleChoice key={"multiplechoice-" + key} row={item.id} number={count} question={item.question} choices={item.choices} field={item.field} text={item.text} />
            case "Ranking":
                return <Ranking key={"ranking-" + key} row={item.id} number={count} question={item.question} choices={item.choices} field={item.field} text={item.text} />
            case "RankingInternal":
                return <MultipleChoice key={"rankinginternal-" + key} row={item.id} number={count} question={item.question} choices={item.choices} field={item.field} text={item.text} />
            case "Heading":
                return <Heading key={"heading-" + key} heading={item.question} textSize={item.textSize} />
            case "Subject":
                return <Heading key={"subject-" + key} heading={item.question} textSize={4} />
            case "Aspect":
                return <Heading key={"aspect-" + key} heading={item.question} textSize={5} />
            case "Paragraph":
                return <Paragraph key={"paragraph-" + key} paragraph={item.question} />
            case "Longform":
                return <Longform key={"longoform-" + key} row={item.id} number={count} question={item.question} />
        }
    })

    return (
        <Fragment>
            {error ?
                <Result
                    status="warning"
                    title="There were some problems locating the feedback form."
                    extra={[]
                    }
                />
                :
                <Fragment>
                    <Form
                        layout="vertical"
                        form={form}
                        style={{ maxWidth: "100%", marginBottom: 40 }}
                        onFinish={handleSubmit}
                    >
                        {formRow}
                        <Form.Item>
                            <Button loading={submitLoading} size="large" onClick={handleSubmit} style={{ marginTop: 5, width: 200 }} block>Submit</Button>
                        </Form.Item>
                    </Form>
                    <GreenWorksTag/>
                    {formError ?
                        <Alert style={{ marginBottom: 40 }} message="Submit error!  Please review the form." type="error" />
                        : ""}
                </Fragment>
            }

        </Fragment>

    )
}

export default withRouter(FeedbackForm);