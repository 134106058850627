//React
import {Switch, BrowserRouter, Route} from 'react-router-dom'

//Components
import Nav from './Components/Nav'
import Footer from './Components/Footer_'
import ProtectedRoute from './Components/ProtectedRoute'

//Sections
import Dashboard from './Sections/Dashboard';
import Login from './Sections/Login';
import Campaigns from './Sections/Campaigns';
import Questionnaires from './Sections/Questionnaires';
import Templates from './Sections/Templates';
import Lists from './Sections/Lists';
import Results from './Sections/Results';
import FormController from './Sections/Form/FormController'
import ListController from './Sections/List/Controller'
import CampaignController from './Sections/Campaign/Controller'
import FeedbackController from './Sections/Feedback/Controller'
import ResultController from './Sections/Result/Controller'
import ContactController from './Sections/Contact/Controller'
import TemplateController from './Sections/Form/TemplateController';

//Contexts
import { UserProvider } from "./Context/UserContext"
import { FormProvider } from "./Context/FormContext"
import { NewListProvider } from "./Context/NewListContext"
import { NewCampaignProvider } from "./Context/NewCampaignContext"

//Styling
import "antd/dist/antd.css";
import { Layout } from 'antd';
import './App.less';

const { Content } = Layout;

function App() {
  return (
      <UserProvider>
        <FormProvider>
          <NewListProvider>
            <NewCampaignProvider>
              <div className="App">
                <Layout style={{minHeight: "100vh"}} className="layout">
                    <BrowserRouter>
                      <Nav/>
                          <Content>
                            <Switch>
                              <Route path="/login" component={Login}/>
                              <Route path="/feedback/:campaignId?/:hash?" component={FeedbackController}/>
                              <Route path="/contact-us" component={ContactController}/>
                              <ProtectedRoute exact path='/' component={Dashboard} />
                              <ProtectedRoute path='/campaigns' component={Campaigns} />
                              <ProtectedRoute path='/contacts' component={Lists} />
                              <ProtectedRoute path='/questionnaires' component={Questionnaires} />
                              <ProtectedRoute path='/templates' component={Templates} />
                              <ProtectedRoute path='/results' component={Results} />
                              <ProtectedRoute path="/template/:filter1?/:filter2?" component={TemplateController}/>
                              <ProtectedRoute path="/questionnaire/:filter1?/:filter2?" component={FormController}/>
                              <ProtectedRoute path="/list/:filter1?/:filter2?" component={ListController}/>
                              <ProtectedRoute path="/campaign/:filter1?/:filter2?" component={CampaignController}/>
                              <ProtectedRoute path="/result/:filter1?/:filter2?/:filter3?" component={ResultController}/>
                            </Switch>
                          </Content>
                      <Footer />
                    </BrowserRouter>
                </Layout>
              </div>
            </NewCampaignProvider>
          </NewListProvider>
        </FormProvider>
      </UserProvider>
    
  );
}

export default App;
