import React, { useContext, useState, Fragment, useEffect } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FormContext } from "../../../Context/FormContext";
import { UserContext } from "../../../Context/UserContext";
import { materialityAPI } from '../../../Components/Helpers';
import Question from './Question'

// Styles
import { Modal, Typography, message, Row, Input, Col, Button, Divider } from 'antd';
import { EditFilled, DeleteFilled, SaveOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { isNullishCoalesce } from "typescript";
const { Title, Text } = Typography;

message.config({
    duration: 2,
    maxCount: 2,
  });

interface IItems {
    id: string,
    text: string,
    field: string,
    choices: any,
    question: string,
    tag: string | null,
    number: number | null
}

interface IDND {
    id: string, 
    text: string, 
    form_id: string | null, 
    field: string,
    choices: any,
    question: string,
    tag: string | null,
    tab: string | null,
    apiUrl: string,
    saveForm?: (items: any) => void
}
const grid = 8;
// a little function to help us with reordering the result
const reorder = (list:any, startIndex:any, endIndex:any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getItemStyle = (isDragging:any, draggableStyle:any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 0,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "rgba(217,217,217,0.15)" : "rgba(217,217,217,0)",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = (isDraggingOver:boolean) => ({
  background: isDraggingOver ? "transparent" : "transparent",
  padding: 0,
  width: '100%'
});

const addNumberToItems = (items:any) => {
    let count:any = 0
    let arr = []
    for (let x of items) {
        let obj:any = x
        if (x.text !== "Subject" && x.text !== "Aspect" && x.text !== "Heading" && x.text !== "Paragraph") {
            count++
            obj['number'] = count
        }
        arr.push(obj)
    }
    return arr
}

const DND : React.FC<IDND> = ({id, text, form_id, choices, question, field, tag, tab, apiUrl, saveForm}: IDND) => {

    
    const [items, setItems] = useState<IItems[]>([])
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [deleteKey, setDeleteKey] = useState("")
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [editKey, setEditKey] = useState("")
    const [listLoaded, setListLoaded] = useState(false)
    const FormState = useContext(FormContext)
    const UserState = useContext(UserContext);
    const [currentItem, setEditCurrentItem] = useState<any | null>({})
    const [editQuestion, setEditQuestion] = useState("")
    const [formName, setFormName] = useState<any>("")
    const [saveLoading, setSaveLoading] = useState<boolean>(false)

    useEffect(() => {   //fired on load, reload from context
        const getForm = async(id:any) => {
            try {
                
                const res = await materialityAPI.get(apiUrl + id)                
                if (res.data.row.data) {
                    const arr = addNumberToItems(res.data.row.data)
                    setItems(arr)
                    if (tab?.toLowerCase() === "internal") {
                        FormState?.setFormItemsInternal(arr)
                    }
                    if (tab?.toLowerCase() === "external") {
                        FormState?.setFormItemsExternal(arr)
                    }
                    
                }
                setFormName(res.data.row.name)
                setListLoaded(true)
            }
            catch(err) {
                console.log(err)
            }
        }

        if(form_id && form_id.length > 0) {
            getForm(form_id)
        }
        
    }, [form_id]);

    useEffect(() => {
        //this fucks up saving because both use the same context, need to fix
        if (id && form_id) {   // fired on edit form being used, add new item
            setItems([...items, {number: null, id: id, text: text, field: field, question: question, choices: choices, tag:tag}])
            if (tab?.toLowerCase() === "internal") {
                FormState?.setFormItemsInternal([...items, {number: null, id: id, text: text, field: field, question: question, choices: choices, tag:tag}]);
            }
            if (tab?.toLowerCase() === "external") {
                FormState?.setFormItemsExternal([...items, {number: null, id: id, text: text, field: field, question: question, choices: choices, tag:tag}]);
            }
            
        }
    }, [id]);

    useEffect(()=> {    //fired when items updated
        
        //handleSaveformItems(null, "edit")

    }, [items]);

    const onDragEnd = (result:any) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      let items_:any = reorder(
        items,
        result.source.index,
        result.destination.index
      )

      const arr = addNumberToItems(items_)
      setItems(arr)
      if (tab?.toLowerCase() === "internal") {
        FormState?.setFormItemsInternal(arr)
      }
      if (tab?.toLowerCase() === "external") {
        FormState?.setFormItemsExternal(arr)
      }
      
    }

    const info = (msg:any) => {
        message.info(msg)
    };

    const errorMessage = (msg:any) => {
        message.error(msg)
    };

    //Delete Module
    const handleDeleteModule = (id:string) => {
        setDeleteModalVisible(!deleteModalVisible);
        setDeleteKey(id);
    }
    const handleDelete = () => {

        setItems(addNumberToItems(items.filter(key => key.id !== deleteKey)))
        if (tab?.toLowerCase() === "internal") {
            FormState?.setFormItemsInternal(addNumberToItems(items.filter(key => key.id !== deleteKey)))
        }
        if (tab?.toLowerCase() === "external") {
            FormState?.setFormItemsExternal(addNumberToItems(items.filter(key => key.id !== deleteKey)))
        }
        
        //
        setDeleteModalVisible(!deleteModalVisible);
    }
    //Edit Module
    const handleEditModule = (id:string) => {
        setEditModalVisible(!editModalVisible)
        setEditKey(id)
        let currentItem = items.find((v) => id === v.id)
        setEditCurrentItem(currentItem)
    }

    const handleSaveformItems = async (e:any, type:string) => {
        let payload = null
        if (type === "edit") {
            payload = {
                owner: UserState?.user._id,
                data: [],
                name: formName
            }
            if (tab?.toLowerCase() === "internal") {
                payload["data"] = FormState?.formItemsInternal
            }
            if (tab?.toLowerCase() === "external") {
                payload["data"] = FormState?.formItemsExternal
            }
        }
        
        if (payload) {
            handleSaveForm(payload, type)
        }
        
    }

    const handleSaveForm = async(payload:any, type:string) => {
        try {
            //setSaveLoading(true)
            if(type === "edit") {
                setEditModalVisible(!editModalVisible);
            }
            //console.log(payload)

            const res = await materialityAPI.put(apiUrl +form_id, payload)
            const reload = await materialityAPI.get(apiUrl +form_id)
            setItems(addNumberToItems(reload.data.row.data))
            if (tab?.toLowerCase() === "internal") {
                FormState?.setFormItemsInternal(addNumberToItems(reload.data.row.data))
            }
            if (tab?.toLowerCase() === "external") {
                FormState?.setFormItemsExternal(addNumberToItems(reload.data.row.data))
            }
            
            //setSaveLoading(false)
            info("Form saved.")
        
        }
        catch(error) {
            console.log(error)
        }
    }

    console.log(items)
  
    return (
        <Fragment>
            <Modal
                title="Deletion"
                visible={deleteModalVisible}
                onOk={() => handleDelete()}
                onCancel={()=> setDeleteModalVisible(!deleteModalVisible)}
            >
            <p>Are you sure you want to delete?</p>
            </Modal>
            <Modal
                title="Edit"
                visible={editModalVisible}
                onOk={(e:any) => handleSaveformItems(e,"edit")}
                onCancel={()=> setEditModalVisible(!editModalVisible)}
            >
                <Question tab={tab} item={currentItem} />
            </Modal>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    >
                    {items.length === 0 ? 
                        <div className="DND-placeholder">
                            <p>Add to your form by selecting questions on the right. </p>
                        </div>
                    : ""}
                    {items.map((item_, index) => (
                        
                        <Draggable key={"draggable-"+item_.id} draggableId={item_.id} index={index}>
                        {(provided, snapshot) => (
                            
                            <div className={item_.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                            )}
                            >
                                <div className={`${item_.text}`} style={{backgroundColor: "rgba(0, 0, 0, 0.65)", width: "100%"}}>
                                    <Text style={{ color: "#FFFFFF", fontSize: 12, paddingLeft: 5}}>{item_.text}</Text>
                                </div>

                                <div className="FormCard__Wrapper">
                                    {item_.tag ? 
                                        <div className={"tag-"+item_.tag}>
                                        </div>
                                    : ""}

                                    <div className="FormCard__Wrapper_Text">
                                        {item_.question && item_.question.length > 0 ? 
                                            <Fragment>{(item_.number) ? item_.number + "." : null} {item_.question}</Fragment>
                                            :
                                            <Fragment>{(item_.number) ? item_.number + "." : null} {item_.text}</Fragment>
                                        }
                                    </div>
                                    <div className={`FormCard__Wrapper_Icons ${item_.text}`}>
                                        <div key={item_.id + "-edit"} className="modify" onClick={() => handleEditModule(item_.id)} >
                                            <EditFilled style={{fontSize: 18}} />
                                        </div>
                                        <div key={item_.id + "-delete"} className="modify" onClick={() => handleDeleteModule(item_.id)} >
                                            <DeleteFilled style={{fontSize: 18}} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                    </div>
                )}
                </Droppable>
            </DragDropContext>
      </Fragment>
    );
}

export default DND;
