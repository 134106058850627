import {Fragment, useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
//styling
import { Typography, Form, Space, Row, Divider, Input } from 'antd';
const { TextArea } = Input;
const { Title, Text } = Typography;


const Longform = (props:any) => {
    
    return (
        <Fragment>
            <Row style={{marginBottom:20}}>
                <Text strong>{props.number}. {props.question}</Text>
            </Row>
            <Row>
                <Form.Item style={{width: '100%'}} name={props.row} rules={[{ required: true, message: 'Please include your response.' }]}>
                    <TextArea rows={6} style={{width: '100%'}}/>
                </Form.Item>
            </Row>
            <Divider/>
        </Fragment>
    )
}

export default Longform