import {Fragment, useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
//styling
import { Typography, Radio, Space, Row, Divider } from 'antd';
const { Title, Text } = Typography;


const PreviewHeading = (props:any) => {
    
    const [heading, setHeading] = useState("")
    const [textSize, setTextSize] = useState<any | null>(3);

    useEffect(()=> {
        console.log(props)
        if(props.heading) {
            setHeading(props.heading)
        }
        if(props.textSize) {
            setTextSize(parseInt(props.textSize))
        }

    }, [])
    
    return (
        <Fragment>
            <Row style={{marginBottom:20}}>
                <Title level={textSize}>{heading}</Title>
            </Row>
            
            <Divider/>
        </Fragment>
    )
}

export default PreviewHeading