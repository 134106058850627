import { useState, useContext} from 'react';
import {withRouter} from 'react-router-dom';
import { Row, Layout, Button, Input, Form, Typography} from 'antd';
import { materialityAPI, loadToken } from '../Components/Helpers'
import { UserContext } from "../Context/UserContext.tsx";
//import { OmitProps } from 'antd/lib/transfer/ListBody';
const { Content } = Layout;  
const { Text } = Typography;

const Login = (props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [showError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
console.log(process.env.NODE_ENV);
console.log(process.env.REACT_APP_STAGE);
    const userState = useContext(UserContext);

    const handleSubmit = async (values) => {
        setLoading(true)
        try {
            const res = await materialityAPI.post('/auth/login', values)
            console.log(res.data.token)
            if (res.data.token) {
                localStorage.setItem('materiality.Token', res.data.token);
                loadToken();

                const user_ = res.data.user_;
                userState?.setUser({
                    email: user_.email,
                    role: user_.role,
                    firstName: user_.firstName,
                    lastName: user_.lastName,
                    company: user_.company,
                    _id: user_._id,
                    token: res.data.token
                });
                userState?.setAuthenticated(true)
                props.history.push("/")
            }
        }
        catch(error) {
            console.log(error)
            setLoading(false)
            if (error.response) {
                setErrorMessage(error.response.statusText + '. ' + error.response.data)
            }
            else {
                setErrorMessage("Network error.  Check back later.")
            }
            setError(true)
        }
        
    };

    return (
        <Layout style={{minHeight: "100vh"}}>
            <Content style={{ padding: '50px 50px' }}>
                <Row>
                    <h2>Login</h2>
                </Row>
                <Form
                    layout="vertical"
                    form={form}
                    style={{maxWidth: "500px"}}
                    onFinish={handleSubmit}
                >
                    <Form.Item name="email" style={{width: '100%'}} rules={[{ type: 'email' }]} >
                        
                        <Input size="large" placeholder="Email" style={{ width: '100%'}}/>
                        
                    </Form.Item>
                    <Form.Item name="password" style={{width: '100%'}} rules={[{ required: true }]}>
                        
                        <Input.Password size="large" placeholder="Password" style={{ width: '100%'}}/>
                        
                    </Form.Item>
                    <Form.Item>
                        <Button loading={loading} size="large" htmlType="submit" type="primary" style={{marginTop: 5}} block>Login</Button>
                    </Form.Item>
                    {showError ? 
                        <Text>{errorMessage}.</Text>
                    : ""}
                </Form>
                
            </Content>
        </Layout>
    );
};
    
export default withRouter(Login);