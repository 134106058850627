import { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { materialityAPI } from '../../Components/Helpers';
import { UserContext } from "../../Context/UserContext";
import { NewListContext } from "../../Context/NewListContext";
import { FormContext } from "../../Context/FormContext";

//styling
import { Layout, Form, Input, Row, Col, Divider, Button, message, Typography, Table, Modal } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { PropertySafetyTwoTone, SaveOutlined } from '@ant-design/icons';
const { Content} = Layout;
const { Text } = Typography;

interface IList {
    key: number;
    name: string;
    email: string;
    _id: string;
}

const NewList = (props:any) => {
    const [form] = Form.useForm()
    const [list, setList] = useState<any | null>([])
    const [listName, setListName] = useState<any>("")
    const [listItemName, setListItemName] = useState<any>("")
    const [listItemIndex, setListItemIndex] = useState<any>("")
    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
    const [saveLoading, setSaveLoading] = useState<boolean>(false)
    const [showError, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const UserState = useContext(UserContext)
    const NewListState = useContext(NewListContext)
    const FormState = useContext(FormContext)

    useEffect(() => {   //fired on load, reload from context
        
    }, []);

    const info = (msg:any) => {
        message.info(msg)
    };

    const handleSaveList = async () => {
        setSaveLoading(true)
        let internalPayload = {
            owner: UserState?.user._id,
            name: `[Internal] ${listName}`,
            type: "internal"
        }
        let externalPayload = {
            owner: UserState?.user._id,
            name: `[External] ${listName}`,
            type: "external"
        }
        let payload = {
            owner: UserState?.user._id,
            name: listName
        }
        try {
            const internalList = await materialityAPI.post('/api/list', internalPayload)
            const externalList = await materialityAPI.post('/api/list', externalPayload)

            let questionnairePayload = {
                owner: UserState?.user._id,
                name: listName,
                lists: [{id: internalList.data.newList._id, type: "internal"}, {id: externalList.data.newList._id, type: "external"}]
            }
            const res = await materialityAPI.post('/api/contactlist', questionnairePayload)
            console.log(res)
            setSaveLoading(false)
            // redirect to edit page
            props.history.push("/list/edit/"+res.data.newContactList._id)
        }
        catch(error) {
            console.log(error)
            setSaveLoading(false)
            info("Error saving form.")
            //setErrorMessage(error.response.statusText + '. ' + error.response.data)
        }
    }

    return (
        <Layout style={{height: "100vh"}}>
            <Content style={{paddingLeft: 40, paddingTop: 40}}>
                <Row style={{alignItems: "center"}}>
                    <Col span={8}>
                        <Input value={listName} onChange={(e)=> setListName(e.target.value)} placeholder="List Name" />
                    </Col>
                    <Col span={2}></Col>
                    <Col span={2}></Col>
                    <Col style={{borderLeft: '1px solid #f0f0f0', paddingLeft: 20}} span={12}>
                        <Button
                            className="actionBox save"
                            type="primary"
                            icon={<SaveOutlined />}
                            loading={saveLoading}
                            onClick={handleSaveList}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
                <Divider/>
            </Content>
            
        </Layout>
    )
}

export default withRouter(NewList);