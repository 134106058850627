import {Link} from 'react-router-dom';

import { useEffect, useContext, useState, Fragment } from 'react';
import { UserContext } from "../Context/UserContext";
import { materialityAPI } from '../Components/Helpers';
import dayjs from 'dayjs';

import { Layout, Typography, Menu, Row, Col, Divider, Table, Modal, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { PlusOutlined } from '@ant-design/icons';
const { Content, Sider } = Layout;
const { Title } = Typography;

interface IList {
    key: number;
    name: string;
    date: string;
    _id: string;
}


const Lists = () => {
    const [lists, setLists] = useState([])
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [deleteKey, setDeleteKey] = useState("")
    const [deleteFormName, setDeleteFormName] = useState("")
    const UserState = useContext(UserContext)

    useEffect(() => {
        const getAllLists = async(user:any) => {
            try {
                const res = await materialityAPI.get('/api/contactlist/all/'+user._id)
                const data = res.data.row.reverse()

                setLists(data)
            }
            catch(err) {
                console.log(err)
            }
        }
        const {user}:any = UserState;
        getAllLists(user);
    }, []);

    const handleDelete = (record:any) => {
        setDeleteModalVisible(!deleteModalVisible)
        setDeleteKey(record._id)
        setDeleteFormName(record.name)
    }

    const deleteList = async() => {
        try {
            const lists = await materialityAPI.get('/api/contactlist/'+deleteKey)
            console.log(lists)
            const listInternal = lists.data.row.lists.find((v:any)=> v.type === "internal")
            const listExternal = lists.data.row.lists.find((v:any)=> v.type === "external")
            await materialityAPI.delete('/api/list/'+listInternal.id)
            await materialityAPI.delete('/api/list/'+listExternal.id)
            await materialityAPI.delete('/api/contactlist/'+deleteKey)
            const {user}:any = UserState;
            const res_all = await materialityAPI.get('/api/contactlist/all/'+user._id)
            const data = res_all.data.row.reverse()
            setLists(data)
            setDeleteModalVisible(!deleteModalVisible)
            message.info("List deleted.")
        }
        catch(error) {
            console.log(error)
            message.info("Error deleting list.")
            setDeleteModalVisible(!deleteModalVisible)
        }
    }

    const columns: ColumnsType<IList> = [
        
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            filterMultiple: false,
            sortDirections: ['descend', 'ascend'],
            render: (text, record, index) => <Link style={{color: "#1DA57A"}} to={"/list/edit/"+record._id}>{text}</Link>
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record, index) => dayjs(text).format('YYYY-MM-DD')
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            filterMultiple: false,
            render: (text, record, index) => <a href="#" style={{color: "#1DA57A"}} onClick={() => handleDelete(record)}>Delete</a>
        },
        
    ];

    return (
        <Layout style={{minHeight: "80vh"}}>
            <Modal
                title={"Delete "+ deleteFormName}
                visible={deleteModalVisible}
                onOk={() => deleteList()}
                onCancel={()=> setDeleteModalVisible(!deleteModalVisible)}
            >
            <p>Are you sure you want to delete?</p>
            </Modal>
            <Content style={{paddingLeft: 40, paddingTop: 40}}>
                    <Row style={{alignItems: "center"}}>
                        <Col span={20}>
                            <Title style={{marginBottom: 0}} level={5}>Contacts</Title>
                        </Col>
                        <Col span={4}>
                            <div className="actionBox">
                                <PlusOutlined style={{ fontSize: '14px' }}/>
                                <Title className="actionBox__title" level={5}>New Contact List</Title>
                                <Link to="/list/create/new"></Link>
                            </div>
                        </Col>
                    </Row>
                    <Divider/>  
                    <Row>
                        <Table style={{width: '90%'}} pagination={{ pageSize: 10 }} dataSource={lists} columns={columns}/>
                    </Row>
                </Content>
        </Layout>
    )
}

export default Lists;