import {Link} from 'react-router-dom';
import clipbord from'../Images/clipboard.svg'
import document from'../Images/document.svg'
import envelope from'../Images/envelope.svg'
import form from'../Images/form.svg'
import gauges from'../Images/gauges.svg'
import mail from'../Images/mail.svg'
import waypoints from'../Images/waypoints.svg'
import dayjs from 'dayjs'

import { Row, Col, Layout, List, Typography, Divider, Card, Tooltip } from 'antd'
import { useEffect, useContext, useState, Fragment } from 'react'
import { materialityAPI } from '../Components/Helpers';
import { UserContext } from "../Context/UserContext";

const { Content } = Layout
const { Title, Text } = Typography
const { Meta } = Card

const Dashboard = () => {

    const UserState = useContext(UserContext)

    const [campaigns, setCampaigns] = useState([])

    useEffect(()=> {
        const getCampaigns = async() => {
            const {user} = UserState;
            const res = await materialityAPI.get('/api/campaignsingle/all/'+user._id)
            console.log(res)
            const running = res.data.row.filter((v)=> v.campaignState.toLowerCase() === "running")
            console.log(running)
            setCampaigns(running)
        }
        getCampaigns()
        
    }, [])

    useEffect(()=> {

    }, [campaigns])

    const campaignRows = campaigns.map((item, index)=> {
        return  <Row key={`${index}-campaigns`}>
                    <Tooltip title={item._id}><Text><strong>{item.name} [{item.campaignType}]</strong></Text></Tooltip>
                    <Text style={{marginLeft:20}}>{dayjs(item.date).format('YYYY-MM-DD')}</Text>
                </Row>
    })

    return (
        <Layout>
            <Content style={{padding: 40}}>
                <Row>
                    <Title level={5}>Dashboard</Title>
                </Row>
                <Divider />
                <Row>
                    <Col style={{ paddingRight: '10px'}} xl={24}>
                    <div className="dashboardCards">
                        <div className="dashboardCards__single">
                            <Link to="/questionnaire/create/new"></Link>
                            <div>
                                <img src={form}/>
                                <Title level={5}>New Questionnaire</Title>
                            </div>
                        </div>
                        <div className="dashboardCards__single">
                            <Link to="/list/create/new"></Link>
                            <div>
                                <img src={mail}/>
                                <Title level={5}>New Contact List</Title>
                            </div>
                        </div>
                        <div className="dashboardCards__single">
                            <Link to="/campaign/create/new"></Link>
                            <div>
                                <img src={waypoints}/>
                                <Title level={5}>New Campaign</Title>
                            </div>
                        </div>
                    </div>
                    
                    </Col>
                </Row>
                <Divider />

                <Row>
                    <Col style={{ paddingRight: '10px'}} xl={18} xs={24}>
                        
                        {campaignRows.length > 0 ? 
                            <Fragment>
                                <Title level={5}>Campaigns currently running:</Title>
                                {campaignRows}
                            </Fragment>
                        : 
                            <h5>There are no campaigns running at this time.</h5>
                        }
                        
                    </Col>
                </Row>
                <Divider />
                <Row>
                    {/* <List
                        header={<div>Recent Actions</div>}
                        bordered
                        dataSource={data}
                        renderItem={item => (
                            <List.Item>
                            <Typography.Text mark>[ACTION]</Typography.Text> {item}
                            </List.Item>
                        )}
                    /> */}
                </Row>    
            </Content>
        </Layout>
    );
};

export default Dashboard;