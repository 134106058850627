import {useContext} from 'react';
import {withRouter, Link} from 'react-router-dom';
import { Layout, Menu, Col, Row } from 'antd';
import {UserContext} from '../Context/UserContext.tsx'

const { Header } = Layout;

const Nav = (props) => {

    const {location} = props
    const userState = useContext(UserContext);
    const { authenticated, user } = userState;

    const handleLogout = () => {
        userState?.setUser(null);
        userState?.setAuthenticated(false)
        localStorage.removeItem('materiality.Token');
        props.history.push("/login")
    }

    return (
        <div style={{borderBottom: "1px solid #d9d9d9"}}>
            <Header style={{padding: '0 40px'}}>
                <Row>
                    <Col span={12}>
                        <h2 className="header__Title">Greenworks Materiality</h2>
                    </Col>
                    <Col style={{textAlign: 'right'}} span={12}>
                        {authenticated ? 
                            <span>{user.email} | <span className="logout" onClick={handleLogout}>Logout</span></span>
                        : 
                            <span><Link to='/login'>Login</Link></span>
                        }
                        
                    </Col>
                </Row>
            </Header>
            <Row style={{background: '#f0f0f0'}}>
                <Col span={24}>
                    {authenticated ? 
                        <Menu selectedKeys={[location.pathname]} style={{padding: '0 20px', background: 'none'}} mode="horizontal">
                            <Menu.Item key="/">
                                <Link to="/">Dashboard</Link>
                            </Menu.Item>
                            <Menu.Item key="/questionnaires">
                                <Link to="/questionnaires">Questionnaires</Link>
                            </Menu.Item>
                            <Menu.Item key="/templates">
                                <Link to="/templates">Templates</Link>
                            </Menu.Item>
                            <Menu.Item key="/contacts">
                                <Link to="/contacts">Contacts</Link>
                            </Menu.Item>
                            <Menu.Item key="/campaigns">
                                <Link to="/campaigns">Campaigns</Link>
                            </Menu.Item>
                            <Menu.Item key="/results">
                                <Link to="/results">Results</Link>
                            </Menu.Item>
                        </Menu>
                    :""}
                </Col>
            </Row>
      </div>
    );
};

export default withRouter(Nav);