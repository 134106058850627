import {Fragment, useState, useEffect} from 'react';
import {Link, withRouter} from 'react-router-dom';
import { materialityAPI } from '../../Components/Helpers'
import { UserContext } from "../../Context/UserContext"

import FeedbackForm from './FeedbackForm';
import FeedbackComplete from './FeedbackComplete';

import { Layout, Result, Row, Col} from 'antd'

const { Content} = Layout

const Controller = (props:any) => {
    //control route for display form (edit or create)
    console.log(props.match.params.campaignId, props.match.params.hash)

    const [loading, setLoading] = useState(true)
    const [errorCheck, setError] = useState(false)
    const [feedbackFormComponent, setFormComponent] = useState(false)
    const [feedbackCompleteComponent, setCompleteComponent] = useState(false)
    const [formId, setFormId] = useState("")
    const [warningText, setWarningText] = useState("There were some problems locating the feedback form.")

    useEffect(()=> {
        console.log('feedback')
        const getCampaign = async() => {
            try {
                // get form from campaign
                let res = await materialityAPI.get('/publicapi/feedback/campaignsingle/'+props.match.params.campaignId)
                
                const { campaignResults, questionnaire, campaignState } = res.data.row
                console.log(campaignResults)
                const formIncomplete = Object.values(campaignResults).find((v:any)=> (v.status === "Incomplete") && (v.hash === props.match.params.hash))
                const formComplete = Object.values(campaignResults).find((v:any)=> (v.status === "Complete") && (v.hash === props.match.params.hash))
                const form:any = Object.values(campaignResults).find((v:any) => (v.hash === props.match.params.hash))
                const { formId } = form
                console.log(formId)
                if (formIncomplete && campaignState === "RUNNING") { // form status is incomplete and campaign running
                    console.log(form)
                    setFormId(formId)
                    setLoading(false)
                    setFormComponent(true)
                }
                else if(formComplete) {
                    setLoading(false)
                    setCompleteComponent(true)
                }
                else if (campaignState !== "RUNNING") {  // campaign is not running
                    setError(true)
                    setLoading(false)
                    setWarningText("Form is not accessible.")
                }
                else {
                    setError(true)
                    setLoading(false)
                    setWarningText("There was a problem retriving the form.")
                }
            }
            catch(error) {
                console.log(error)
                setError(true)
                setLoading(false)
            }
        }

        if (props.match.params.campaignId && props.match.params.hash) {
            // look up campaign and member hash
            getCampaign();
        }
        else {
            // give error page
            console.log('error')
            setError(true)
            setLoading(false)
        }

    }, [])

    const feedbackComplete = () => {
        setFormComponent(false)
        setCompleteComponent(true)
    }

    return (
        <Content style={{ paddingLeft: 40, paddingTop: 40 }}>
            <Row style={{ alignItems: "center" }}>
                <Col span={4}></Col>
                <Col span={16}>
                    <Fragment>
                        {loading ?
                            <Fragment>Loading...</Fragment>
                        :
                            <Fragment>
                                {!errorCheck ?
                                    <Fragment>
                                        {feedbackFormComponent ? 
                                            <FeedbackForm formId={formId} feedbackComplete={feedbackComplete}/>
                                        : ""}
                                    
                                        {feedbackCompleteComponent ? 
                                            <FeedbackComplete/>
                                        : ""}
                                    </Fragment>
                                : 
                                    <Fragment>
                                        <Result
                                            status="warning"
                                            title={warningText}
                                            extra={[]
                                            }
                                        />
                                    </Fragment>
                                }
                            </Fragment>
                        }
                    </Fragment>
                </Col>
                <Col span={4}></Col>
            </Row>
        </Content>
        

    )
}

export default withRouter(Controller);