import {Fragment, useEffect, useState} from 'react';

//styling
import { Typography, Form, Space, Row, Divider, Input, InputNumber } from 'antd';
const { Title, Text } = Typography;


const Ranking = (props:any) => {
    
    const [choices, setChoices] = useState([])

    useEffect(()=> {

        setChoices(props.choices)

    }, [])

    const options = choices.map((item:any, index:any) => {
        return  <Form.Item name={props.row + "-" + index} rules={[{ required: true, message: "Input required!" }]}>
                    <Space>
                        <Input key={"ranking-"+props.row+"-"+index} style={{width:60}} type="number" min={0}/><Text>{item.option}</Text>
                    </Space>
                </Form.Item>
    })
    
    return (
        <Fragment>
            <Row style={{marginBottom:20}}>
                <Text strong>{props.number}. {props.question}</Text>
            </Row>
            <Row>
                <Space direction="vertical" style={{marginLeft:25}}>
                    {options}
                </Space>
            </Row>
            <Divider/>
        </Fragment>
    )
}

export default Ranking