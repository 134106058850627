import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {RouteComponentProps} from "react-router";
import { materialityAPI } from '../../Components/Helpers'

import MultipleChoice from './Components/Preview/PreviewMultipleChoice'
import Ranking from './Components/Preview/PreviewRanking'
import Heading from './Components/Preview/PreviewHeading'
import Paragraph from './Components/Preview/PreviewParagraph'
import Longform from './Components/Preview/PreviewLongform'

//styling
import { Row, Col, Layout } from 'antd';
const { Content } = Layout;

interface IPreviewForm extends RouteComponentProps{
    form_id: any;
    apiUrl: string;
}

const PreviewForm = (props: IPreviewForm) => {

    const [formData, setFormData] = useState([])

    useEffect(() => {
        console.log(props.form_id)
        const getForm = async () => {
            try {
                let res = await materialityAPI.get(props.apiUrl + props.form_id)
                const { data } = res.data.row
                setFormData(data)

            }
            catch (error) {
                console.log(error)

            }
        }

        if (props.form_id) {
            // look up campaign and member hash
            getForm();
        }
        else {
            // give error page
            console.log('error')

        }

    }, [])

    let count = 0;

    const formRow = formData.map((item: any, key: any) => {
        if(item.text==="Multiple Choice" || item.text==="Ranking" || item.text==="RankingInternal" || item.text==="Longform") {count++}
        switch (item.text) {
            case "Multiple Choice":
                return <MultipleChoice key={"multiplechoice-" + key} number={count} question={item.question} choices={item.choices} field={item.field} text={item.text} />
            case "Ranking":
                return <Ranking key={"ranking-" + key} number={count} question={item.question} choices={item.choices} field={item.field} text={item.text} />
            case "RankingInternal":
                return <MultipleChoice key={"multiplechoice-" + key} number={count} question={item.question} choices={item.choices} field={item.field} text={item.text} />
            case "Heading":
                return <Heading key={"heading-" + key} heading={item.question} textSize={item.textSize}/>
            case "Subject":
                return <Heading key={"heading-" + key} heading={item.question} textSize={item.textSize}/>
            case "Aspect":
                return <Paragraph key={"paragraph-" + key} paragraph={item.question} />
            case "Paragraph":
                return <Paragraph key={"paragraph-" + key} paragraph={item.question} />
            case "Longform":
                return <Longform key={"longoform-" + key} number={count} question={item.question} />
        }
    })

    return (
        <Content style={{ paddingLeft: 40, paddingTop: 40 }}>
            <Row style={{ alignItems: "center" }}>
                <Col span={4}>

                </Col>
                <Col span={16}>
                    {formRow}
                </Col>
                <Col span={4}>

                </Col>
            </Row>
        </Content>
    )
}

export default withRouter(PreviewForm);