import { Select } from 'antd';
const { Option } = Select

export const StakeholderTypes: string[] = [
    "Current Investor",
    "Potential ESG Investor",
    "Customer",
    "Client",
    "Employee (Mid Mgmt)",
    "Employee (Jnr Level)",
    "Employee (Field/Factory)",
    "Rating Agency",
    "Community Member",
    "Supplier/Vendor",
    "Government",
    "Regulator",
    "NGO/Charity",
    "Partners",
    "Board Advisors",
    "Board Director",
    "Other"];

export const StakeholderTypeOptions = StakeholderTypes.sort().map((item: any, index: any) => {
    return <Option value={item}>{item}</Option>
});