import {Fragment, useState, useEffect} from 'react';
import {Link, withRouter} from 'react-router-dom';
import { materialityAPI, hostname } from '../../../Components/Helpers';

import { Layout, Typography, Tabs, Row, Col, Divider, Button, message, Drawer, Table, Modal, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table'
import { SaveOutlined } from '@ant-design/icons'
const { Content } = Layout
const { TabPane } = Tabs
const { Text, Title } = Typography

interface IData {
    question: string;
    subject: string;
    answer: string;
    weight: string;
    className: string;
}
interface IDataMC {
    question: string;
    subject: string;
    aspect: string;
    answer: string;
    weight: string;
    className: string;
}

const DataTable = (props:any) => {
    
    const [dataRow, setDataRows] = useState<any | null>([])
    const [dataCols, setDataCols] = useState<any | null>([])
    const [dataColsMultipleChoice, setDataColsMultipleChoice] = useState<any | null>([])
    const [dataTableRanking, setDataTableRanking] = useState<any | null>([])
    const [dataTableMultipleChoice, setDataTableMultipleChoice] = useState<any | null>([])
    const [dataTableLongform, setDataTableLongform] = useState<any | null>([])
    const [csvDataRanking, setCSVDataRanking] = useState<any | null>([])
    const [csvDataMultipleChoice, setCSVDataMultipleChoice] = useState<any | null>([])
    const [csvDataLongform, setCSVDataLongform] = useState<any | null>([])
    const [csvHeaderRanking, setCSVHeaderRanking] = useState<any | null>([])
    const [csvHeaderMultipleChoice, setCSVHeaderMultipleChoice] = useState<any | null>([])
    const [csvHeaderLongform, setCSVHeaderLongform] = useState<any | null>([])
    const [downloadModalVisible, setDownloadModalVisible] = useState<boolean>(false)
    const [path, setPath] = useState("")

    useEffect(()=> {
        console.log(props.stakeholder_definition)
        const getCampaign = async() => {
            try {
                const res = await materialityAPI.get('/api/campaign/'+props.campaign_id)
                const { campaigns } = res.data.row
                const campaignSingle = campaigns.find((v:any)=> props.stakeholder_definition.toLowerCase() === v.type.toLowerCase())
                const resSingle = await materialityAPI.get('/api/campaignsingle/'+campaignSingle.id)
                const {form, campaignResults} = resSingle.data.row

                //get form
                const resForm = await materialityAPI.get('/api/form/'+form)
                const {data} = resForm.data.row

                //get stakeholders that match internal/external
                const stakeholderTable:any = Object.values(campaignResults)

                /**
                 * CSV Creation
                 * @params csvRecords = rows
                 * @parmas csvRowsHeader = header
                 */
                const csvRecordsRanking:any = await dataCompilation(stakeholderTable, data, ["Ranking"])
                const csvRecordsMultipleChoice:any = await dataCompilation(stakeholderTable, data, ["Multiple Choice", "RankingInternal"])
                const csvRecordsLongform:any = await dataCompilation(stakeholderTable, data, ["Longform"])

                const csvRowsHeaderRanking:any = [
                    {id: 'question', title: 'Question'},
                    {id: 'subject', title: 'Subject'}
                ]
                const csvRowsHeaderMultipleChoice:any = [
                    {id: 'question', title: 'Question'},
                    {id: 'subject', title: 'Subject'},
                    {id: 'aspect', title: 'Aspect'}
                ]
                const csvRowsHeaderLongform:any = [
                    {id: 'question', title: 'Question'}
                ]
                for (let y in stakeholderTable) {
                    let temp = {
                        id: stakeholderTable[y].memberEmail, title: stakeholderTable[y].memberEmail
                    }
                    csvRowsHeaderRanking.push(temp)
                    csvRowsHeaderMultipleChoice.push(temp)
                    csvRowsHeaderLongform.push(temp)
                }
                
                setCSVDataRanking(csvRecordsRanking)
                setCSVDataMultipleChoice(csvRecordsMultipleChoice)
                setCSVDataLongform(csvRecordsLongform)

                setCSVHeaderRanking(csvRowsHeaderRanking)
                setCSVHeaderMultipleChoice(csvRowsHeaderMultipleChoice)
                setCSVHeaderLongform(csvRowsHeaderLongform)

                /**
                 * Table Creation
                 * @params cols = table columns
                 * @parmas table = table rows
                 */

                const rowsRanking:any = await dataCompilation(stakeholderTable, data, ["Ranking"])
                const rowsMultipleChoice:any = await dataCompilation(stakeholderTable, data, ["Multiple Choice", "RankingInternal"])
                const rowsLongform:any = await dataCompilation(stakeholderTable, data, ["Longform"])
                
                //first col of table
                const table = [
                    {   "title": "Question",
                        "dataIndex": "question",
                        "key": "question",
                        "fixed": 'left',
                        "width": 300
                    },
                    {   "title": "Subject",
                        "dataIndex": "subject",
                        "key": "subject",
                        "width": 200
                    }
                ]
                const tableMultipleChoice = [
                    {   "title": "Question",
                        "dataIndex": "question",
                        "key": "question",
                        "fixed": 'left',
                        "width": 300
                    },
                    {   "title": "Subject",
                        "dataIndex": "subject",
                        "key": "subject",
                        "width": 200
                    },
                    {
                        "title": "Aspect",
                        "dataIndex": "aspect",
                        "key": "aspect",
                        "width": 200
                    }
                ]
                
                //add cols of stakeholder emails
                for (let x of stakeholderTable) {
                    let obj:any = {}
                    obj["title"] = `${x.memberFirstName} ${x.memberLastName}`
                    obj["dataIndex"] = `${x.memberEmail}`
                    obj["key"] = `${x.memberEmail}`
                    obj["width"] = 200
                    obj["ellipsis"] = true
                    let render = (key:any) => <span><Tooltip title={key}>{key}</Tooltip></span>
                    
                    obj["render"] = render
                    table.push(obj)
                    tableMultipleChoice.push(obj)
                }

                //set rows
                setDataTableRanking(rowsRanking)
                setDataTableMultipleChoice(rowsMultipleChoice)
                setDataTableLongform(rowsLongform)
                //set cols
                setDataCols(table)
                setDataColsMultipleChoice(tableMultipleChoice)
            }
            catch (err) {
                console.log(err)
            }
        }

        getCampaign()
    }, [])

    const handleExport = async() => {
        console.log('exporting...')
        const dataRanking = {
            'header': csvHeaderRanking,
            'data': csvDataRanking,
            'type': 'ranking'
        }
        const dataMultipleChoice = {
            'header': csvHeaderMultipleChoice,
            'data': csvDataMultipleChoice,
            'type': 'multiplechoice'
        }
        const dataLongform = {
            'header': csvHeaderLongform,
            'data': csvDataLongform,
            'type': 'longform'
        }
        
        try {
            const resRanking = await materialityAPI.post('/api/campaignsingle/file', dataRanking)
            const resMultipleChoice = await materialityAPI.post('/api/campaignsingle/file', dataMultipleChoice)
            const resLongform = await materialityAPI.post('/api/campaignsingle/file', dataLongform)

            const rankingPath = resRanking.data.path
            const mcPath = resMultipleChoice.data.path
            const longformPath = resLongform.data.path

            const paths = [rankingPath, mcPath, longformPath]

            const resZip = await materialityAPI.post('/api/campaignsingle/zip', paths)

            const {path} = resZip.data

            setPath(path)
            setDownloadModalVisible(!downloadModalVisible)
        }
        catch(err) {
            console.log(err)
        }
        

    }

    const columns: ColumnsType<IData> = dataCols
    const columnsMC: ColumnsType<IDataMC> = dataColsMultipleChoice
    
    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Content style={{ padding: 40 }}>
                <Modal
                    title={"Export CSV"}
                    visible={downloadModalVisible}
                    onOk={() => setDownloadModalVisible(!downloadModalVisible)}
                    onCancel={()=> setDownloadModalVisible(!downloadModalVisible)}
                >
                    <p>Click the following link to download the CSV:</p>
                    <a className="green-link" download={path} href={hostname+"/api/"+path}>Download</a>
                </Modal>
                <Row>
                    <Title level={5}><Link style={{color: '#1DA57A'}} to="/results">Results</Link> &gt; {props.name} &gt; Data</Title>
                </Row>
                <Divider/>
                <Row justify="end">
                    <div onClick={handleExport} style={{marginBottom:0, marginLeft: 20}} className="actionBox">
                        <Title style={{margin: 0}} className="actionBox__title" level={5}>Export CSV</Title>
                    </div>
                </Row>

                <Divider/>
                <Row>
                    <Tabs style={{ width: '100%' }} defaultActiveKey="internal">
                        <TabPane tab="Ranking" key="ranking">
                            <Table 
                                style={{ width: '100%' }} 
                                pagination={{ pageSize: 60 }} 
                                dataSource={dataTableRanking} 
                                columns={columns}
                                rowClassName={(record, index) => (record.className)}
                                className="dataTable"
                                scroll={{ x: 1300 }}
                            />
                        </TabPane>
                        <TabPane tab="Multiple Choice" key="multiple_choice">
                            <Table 
                                style={{ width: '100%' }} 
                                pagination={{ pageSize: 60 }} 
                                dataSource={dataTableMultipleChoice} 
                                columns={columnsMC}
                                rowClassName={(record, index) => (record.className)}
                                className="dataTable"
                                scroll={{ x: 1300 }}
                            />
                        </TabPane>
                        <TabPane tab="Longform" key="longform">
                            <Table 
                                style={{ width: '100%' }} 
                                pagination={{ pageSize: 60 }} 
                                dataSource={dataTableLongform} 
                                columns={columns}
                                rowClassName={(record, index) => (record.className)}
                                className="dataTable"
                                scroll={{ x: 1300 }}
                            />
                        </TabPane>
                    </Tabs>
                    
                </Row>
            </Content>
            
        </Layout>

    )
}

export default DataTable;

const dataCompilation = async(stakeholderTable:any, data:any, textTypes:string[]) => {
    const rows:any = []
    let aspect:string = ""
    let subject:string = ""
    
    for (let x of data) {  //form data, use to compare to answers
        
        const { id } = x

        if (x.text !== "Heading" && x.text !== "Paragraph" && x.text !== "Subject" && x.text !== "Aspect" && x.text !== "Longform") {

            if (x.text === "Multiple Choice" && textTypes.find((v:any) => v === x.text) || x.text === "RankingInternal" && textTypes.find((v:any) => v === x.text)) {

                let obj:any = {}
                obj["question"] = x.question
                obj["subject"] = subject
                obj["aspect"] = aspect
                obj["className"] = ""
                for (let y in stakeholderTable) { // user answers
                    // get formresult and match id to key
                    
                    let tempEmail = stakeholderTable[y].memberEmail
                    const current = stakeholderTable[y].formResultFormatted.findIndex((v:any)=> id === v.id)
                    if (current > -1) {
                        obj[tempEmail] = stakeholderTable[y].formResultFormatted[current].answers[0].score
                    }
                }

                rows.push(obj)
            }
            if(x.text === "Ranking" && textTypes.find((v:any) => v === x.text)) {  //ranking

                let obj:any = {}

                //question row has blank cols
                obj["question"] = `${x.question}`
                obj["className"] = "grey"
                obj["subject"] = subject
                obj["aspect"] = aspect
                rows.push(obj)

                for (let xx of x.choices) {
                    let rowObj:any = {}
                    rowObj['question'] = xx.option
                    rowObj['subject'] = x.tag
                    for (let xxx of stakeholderTable) {
                        //console.log(id, xxx.formResultFormatted)
                        const userAnswer = xxx.formResultFormatted.find((v:any)=> id === v.id)
                        if(userAnswer) {
                            const answerChoice = userAnswer.answers.find((v:any)=> v.answer === xx.option)
                            if(answerChoice) {
                                rowObj[xxx.memberEmail] = answerChoice.score
                            }
                            
                        }
                    }
                    rows.push(rowObj)
                }
            }
        }
        else if (x.text === "Longform" && textTypes.find((v:any) => v === x.text)) {

            //longform
            let obj:any = {}
            obj["question"] = x.question
            obj["className"] = "grey"
            rows.push(obj)

            //Answer Row
            let rowObj:any = {}
            rowObj['question'] = "Answer"
            for (let y in stakeholderTable) { // user answers
                // get formresult and match id to key
                
                let tempEmail = stakeholderTable[y].memberEmail
                const current = stakeholderTable[y].formResultFormatted.findIndex((v:any)=> id === v.id)
                if (current > -1) {
                    rowObj[tempEmail] = stakeholderTable[y].formResultFormatted[current].answers[0].answer
                }
            }
            rows.push(rowObj)
            
        }
        
        else if (x.text === "Subject") {

            //subject
            console.log('subject')
            subject = x.question
        }
        else if (x.text === "Aspect") {

            //aspect
            console.log('aspect')
            aspect = x.question
        }

    }
    return rows
}