import { useState, useContext, useEffect } from 'react'
import {Link} from 'react-router-dom'
import DND from './Components/DND'
import { materialityAPI } from '../../Components/Helpers'
import { FormContext } from "../../Context/FormContext"
import { UserContext } from "../../Context/UserContext"
import Form from "./Form"

//styling
import { Layout, Typography, Input, Row, Col, Divider, Button, message, Modal, Tabs, Select } from 'antd'
import { SaveOutlined, UploadOutlined, ReloadOutlined } from '@ant-design/icons'
const { Content} = Layout
const {Text, Title} = Typography
const { TabPane } = Tabs
const { Option } = Select

message.config({
    duration: 2,
    maxCount: 2,
  });

const QuestionnaireEdit = (props:any) => {
    const [newFormItem, setFormItem] = useState<any | null>({});
    const [formName, setFormName] = useState<any>("")
    const [formItemName, setFormItemName] = useState<any>("")
    const [formItemIndex, setFormItemIndex] = useState<any>("")
    const [saveLoading, setSaveLoading] = useState<boolean>(false)
    const [errorLoading, setErrorLoading] = useState<boolean>(false)
    const [errorLoadingMessage, setErrorLoadingMessage] = useState<any>("")
    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
    const [templateModalVisible, setTemplateModalVisible] = useState<boolean>(false)
    const [showError, setError] = useState(false)
    const [formLoaded, setFormLoaded] = useState(false)
    const [unsaved, setUnsaved] = useState(false)
    const [currentTab, setCurrentTab] = useState<any>("")
    const FormState = useContext(FormContext);
    const UserState = useContext(UserContext);
    const [internalFormItem, setInternalFormItem] = useState<any | null>({});
    const [externalFormItem, setExternalFormItem] = useState<any | null>({});
    const [internalTemplate, setInternalTemplate] = useState<any>("");
    const [internalId, setInternalId] = useState<any>("")
    const [externalId, setExternalId] = useState<any>("")
    const [formTemplates, setFormTemplates] = useState<any | null>([]);

    useEffect(() => {   //fired on load, get form from db
        ///clear context
        // FormState?.setFormItemsInternal([])
        // FormState?.setFormItemsExternal([])
        // load from db
        handleLoadQuestionnaire()
    
    }, []);

    useEffect(() => {   //save form name when updated
        setUnsaved(true)
    }, [formName]);

    const info = (msg:any) => {
        message.info(msg)
    };

    const errorMessage = (msg:any) => {
        message.error(msg)
    };

    const handleLoadQuestionnaire = async() => {
        // need to show error message if bad request
        try {
            const res = await materialityAPI.get('/api/questionnaire/'+props.form_id)
            console.log(res)
            
            const {forms, name} = res.data.row
            const internal_id = forms.find((v:any)=> v.type === "internal")
            const external_id = forms.find((v:any)=> v.type === "external")

            setFormName(name)
            setInternalId(internal_id.id)
            setExternalId(external_id.id)
        }
        catch(error) {
            console.log(error)
            errorMessage("Error retrieving questionnaire.")
            //setErrorLoadingMessage(error.response.statusText)
            setErrorLoading(true)
        }
    }
    
    const handleTab = async(key:string) => {
        setCurrentTab(key)
    }

    
    return (
        <Layout style={{minHeight: "100vh"}}>

            <Content style={{padding: 40}}>
                    
                <Row>
                    <Col span={18}>
                        {/* <Input style={{width: '60%'}} value={formName} onChange={(e)=> setFormName(e.target.value)} placeholder="Form Name" /> */}
                        <Title level={5}><Link style={{color: '#1DA57A'}} to="/questionnaires">Questionnaires</Link> &gt; {formName}</Title>
                    </Col>
                    <Col style={{borderLeft: '1px solid #f0f0f0', paddingLeft: 20}} span={6}>
                        
                    </Col>
                </Row>
                <Divider/>
                <Row>
                    {errorLoading ? 
                        <div><p>Error loading questionnaire.</p><p><i>{errorLoadingMessage}</i></p></div>
                    :
                        <Tabs style={{width: '100%'}} onTabClick={handleTab} defaultActiveKey="1">
                            <TabPane tab="Internal" key="internal">
                                <Form
                                    id={internalId}
                                    string="Internal"
                                />
                            </TabPane>
                            <TabPane tab="External" key="external">
                                <Form
                                    id={externalId}
                                    string="External"
                                />
                            </TabPane>
                        </Tabs>
                    }
                </Row>
            </Content>
            
        </Layout>
    )
}

export default QuestionnaireEdit;