import { useState, useContext, useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { materialityAPI } from '../../Components/Helpers'
import { UserContext } from "../../Context/UserContext"
import PreviewEmail from "./Components/PreviewEmail"
import Results from "../Result/Components/View"
import CampaignSingle from "./CampaignSingle"
import dayjs from "dayjs"

//styling
import { Layout, Collapse, Drawer, Input, Row, Col, Divider, Button, message, Typography, Table, Modal, Select, Space, Switch, Tabs, Spin, Checkbox } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { CheckOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons'
const { Content, Sider } = Layout
const { Panel } = Collapse
const { Text, Title } = Typography
const { Option } = Select
const { TextArea } = Input
const { TabPane } = Tabs

const EditCampaign = (props: any) => {
    const [campaignResults, setCampaignResults] = useState<any | null>([])
    const [campaignName, setCampaignName] = useState<any>("")
    const [saveLoading, setSaveLoading] = useState<boolean>(false)
    const [showError, setError] = useState(false)
    const [lists, setLists] = useState<any | null>([])
    const [forms, setForms] = useState<any | null>([])
    const [campaignForm, setCampaignForm] = useState<any | null>("")
    const [campaignList, setCampaignList] = useState<any | null>("")
    const [campaignFormId, setCampaignFormId] = useState<any | null>("")
    const [campaignListId, setCampaignListId] = useState<any | null>("")
    const [campaignStatus, setCampaignStatus] = useState<any | null>("")
    const [campaignStatusSwitch, setCampaignStatusSwitch] = useState<boolean>(false)
    const [changeMailingListModal, setChangeMailingListModal] = useState<boolean>(false)
    const [changeFormModal, setChangeFormModal] = useState<boolean>(false)
    const [missingList, setMissingList] = useState<boolean>(false)
    const [missingQuestionnaire, setMissingQuestionnaire] = useState<boolean>(false)
    
    const [previewData, setPreviewData] = useState<any | null>({})

    const [internalString, setInternalString] = useState("")
    const [externalString, setExternalString] = useState("")

    const UserState = useContext(UserContext)

    const info = (msg: any) => {
        message.info(msg)
    };

    const errorMessage = (msg:any) => {
        message.error(msg)
    };

    useEffect(() => {   //fired on load, load from database

        const getCampaign = async () => {
            try {
                const res = await materialityAPI.get('/api/campaign/'+props.campaign_id)
                const { contactList, questionnaire, name, campaignState  } = res.data.row
                console.log(contactList, questionnaire)
                const resList = await materialityAPI.get('/api/contactlist/all/'+UserState?.user._id)
                const resForm = await materialityAPI.get('/api/questionnaire/all/'+UserState?.user._id)

                if (resForm.data.row) {
                    setForms(resForm.data.row)
                }

                if(resList.data.row) {
                    setLists(resList.data.row)
                }

                if (contactList) {
                    const campaignList = await materialityAPI.get('/api/contactlist/'+contactList)
                    console.log(campaignList)
                    if (campaignList.data.row && contactList) {
                        setCampaignListId(contactList)
                        setCampaignList(campaignList.data.row)
                    }
                    else {
                        setMissingList(true)
                    }
                }
                else {
                    //missing list
                    console.log('missing list')
                    setMissingList(true)
                }

                if (questionnaire) {
                    const campaignForm = await materialityAPI.get('/api/questionnaire/'+questionnaire)
                    console.log(campaignForm)
                    if (campaignForm.data.row && questionnaire) {
                        
                        setCampaignFormId(questionnaire)
                        setCampaignForm(campaignForm.data.row)
                    }
                    else {
                        setMissingQuestionnaire(true)
                    }
                }
                else {
                    //missing questionnaire
                    console.log('missing questionnaire')
                    setMissingQuestionnaire(true)
                }

                setCampaignName(name)
                setCampaignStatus(campaignState)

            }
            catch(error) {
                console.log(error)
                errorMessage("Error loading campaign.")
            }
            
        }
        getCampaign()

    }, [campaignStatusSwitch]);

    /**
     * Handle changing the campaign
     */
    const changeForm = async() => {
        let payload = {
            questionnaire: campaignFormId
        }
        const currentform = forms.find((v:any)=> v._id === campaignFormId)
        setCampaignForm(currentform)
        console.log('changing form')
        try {
            const res = await materialityAPI.put('/api/campaign/'+props.campaign_id, payload)
            setChangeFormModal(!changeFormModal)
            setSaveLoading(false)
            info("Campaign saved.")
            setMissingQuestionnaire(false)
        }
        catch(error) {
            console.log(error)
            setSaveLoading(false)
            errorMessage("Error saving campaign.")
        }
    }

    const changeMailingList = async() => { //update campaign
        let payload = {
            contactList: campaignListId
        }
        const currentlist = lists.find((v:any)=> v._id === campaignListId)
        setCampaignList(currentlist)
        console.log('changing list')
        try {
            const res = await materialityAPI.put('/api/campaign/'+props.campaign_id, payload)
            setChangeMailingListModal(!changeMailingListModal)
            setSaveLoading(false)
            info("Campaign saved.")
            setMissingList(false)
        }
        catch(error) {
            console.log(error)
            setSaveLoading(false)
            errorMessage("Error saving campaign.")
        }
    }

    const handleResultsTab = async(key:string) => {
        // refresh results
        if (key === "2") {
            try {
                const res = await materialityAPI.get('/api/campaign/'+props.campaign_id)
                const { campaignResults} = res.data.row
                setCampaignResults(campaignResults)
            }
            catch(err) {
                console.log(err)
            }
        }
    }

    const listOptions = lists.map((item: any, index: any) => {
        return <Option key={item._id} value={item._id}>{item.name}</Option>
    });

    const formOptions = forms.map((item: any, index: any) => {
        return <Option key={item._id} value={item._id}>{item.name}</Option>
    });

    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Modal
                title={"Change Mailing List"}
                visible={changeMailingListModal}
                onOk={() => changeMailingList()}
                onCancel={()=> setChangeMailingListModal(!changeMailingListModal)}
            >
                <Select placeholder="List" value={campaignListId} onChange={(e)=> setCampaignListId(e)} style={{ width: 300 }} >
                    {listOptions}
                </Select>
            </Modal>
            <Modal
                title={"Change Form"}
                visible={changeFormModal}
                onOk={() => changeForm()}
                onCancel={()=> setChangeFormModal(!changeFormModal)}
            >
                <Select placeholder="Form" value={campaignFormId} onChange={(e)=> setCampaignFormId(e)} style={{ width: 300 }} >
                    {formOptions}
                </Select>
            </Modal>
            
            <Content style={{ padding:40 }}>
                <Row>
                    <Col span={12}>
                        <Row style={{ alignItems: "center" }}>
                            <Title style={{marginBottom: 0}} level={5}><Link style={{color: '#1DA57A'}} to="/campaigns">Campaigns</Link> &gt; {campaignName}</Title>
                        </Row>
                        <Row style={{marginTop: 20, border: '1px solid #ddd', width: '95%', padding: 20}}>
                            <Title style={{width: '100%', borderBottom: '1px solid #ddd', textTransform: 'capitalize'}} level={5}>{props.type} Mailing List</Title>
                            <Col span={18}>
                                {missingList ? 
                                    <Text style={{color: "#f5222d"}} >Missing List</Text>
                                : 
                                    <Link style={{color: "#1DA57A", fontSize: 18}} to={"/list/edit/"+campaignList._id} >{campaignList.name}</Link>
                                }
                            </Col>
                            <Col style={{textAlign: 'right'}} span={6}>
                                {campaignStatus === "CLOSED" ? 
                                    <Button onClick={() => setChangeMailingListModal(!changeMailingListModal)}>Change</Button>
                                : ""}
                            </Col>
                        </Row>
                        <Row style={{marginTop: 20, border: '1px solid #ddd', width: '95%', padding: 20}}>
                            <Title style={{width: '100%', borderBottom: '1px solid #ddd', textTransform: 'capitalize'}} level={5}>{props.type} Questionnaire</Title>
                            <Col span={18}>
                                {missingQuestionnaire ? 
                                    <Text style={{color: "#f5222d"}} >Missing Questionnaire</Text>
                                : 
                                    <Link style={{color: "#1DA57A", fontSize: 18}} to={"/questionnaire/edit/"+campaignForm._id} >{campaignForm.name}</Link>
                                }
                            </Col>
                            <Col style={{textAlign: 'right'}} span={6}>
                                {campaignStatus === "CLOSED" ? 
                                    <Button onClick={() => setChangeFormModal(!changeMailingListModal)}>Change</Button>
                                : ""}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Tabs style={{width: '100%', marginTop: 20}} defaultActiveKey="1">
                    <TabPane forceRender={true} tab="Internal" key="internal">
                        <CampaignSingle 
                            campaign_list_id={campaignListId}
                            campaign_form_id={campaignFormId} 
                            type="internal"
                            campaign_id={props.campaign_id}
                        />
                    </TabPane>
                    <TabPane forceRender={true} tab="External" key="external">
                        <CampaignSingle 
                            campaign_list_id={campaignListId}
                            campaign_form_id={campaignFormId} 
                            type="external"
                            campaign_id={props.campaign_id}
                        />
                    </TabPane>
                </Tabs>
                
                
            </Content>

        </Layout>
    )
}

export default withRouter(EditCampaign);