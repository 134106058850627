import {Fragment, useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
//styling
import { Typography, Radio, Space, Row, Divider, Input } from 'antd';
const { TextArea } = Input;
const { Title, Text } = Typography;


const PreviewLongform = (props:any) => {
    
    const [heading, setHeading] = useState("")
    const [textSize, setTextSize] = useState<any | null>(3);

    useEffect(()=> {
        console.log(props.number)
        console.log(props.question)
    }, [])
    
    return (
        <Fragment>
            <Row style={{marginBottom:20}}>
                <Text strong>{props.number}. {props.question}</Text>
            </Row>
            <Row>
                <TextArea/>
            </Row>
            <Divider/>
        </Fragment>
    )
}

export default PreviewLongform