import {Fragment, useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
//styling
import { Typography, Radio, Space, Row, Divider } from 'antd';
const { Title, Text } = Typography;


const PreviewParagraph = (props:any) => {
    
    const [paragraph, setParagraph] = useState("")

    useEffect(()=> {
        console.log(props.paragraph)
        setParagraph(props.paragraph)

    }, [])
    
    return (
        <Fragment>
            <Row style={{marginBottom:20}}>
                <div style={{whiteSpace: "pre-wrap"}}>{paragraph}</div>
            </Row>
            
            <Divider/>
        </Fragment>
    )
}

export default PreviewParagraph