import {Fragment} from 'react';
import {Link, withRouter} from 'react-router-dom';

import { Row, Col, Layout, List, Typography, Divider, Card, Tooltip } from 'antd'

import ContactForm from './Components/Form'


const { Content } = Layout
const { Title, Text } = Typography
const { Meta } = Card

const Controller = (props:any) => {
    //control route for display form (edit or create)
    
    return (
        <Layout>
            <Content style={{padding: 40}}>
                <Row>
                    <Col lg={8} sm={24} style={{paddingRight:40}}>
                        <ContactForm/>
                    </Col>
                    <Col lg={16} sm={24}>
                        <iframe id="contactPDF" src='https://greenworksep-public.s3.ca-central-1.amazonaws.com/GreenWorks+Connects_COMBINED_compressed.pdf#toolbar=0&navpanes=0&zoom=FitH?scrollbar=0' width='100%' height='600px'>
                            This browser does not support PDFs. Please download the PDF to view it: <a href='https://greenworksep-public.s3.ca-central-1.amazonaws.com/GreenWorks+Connects_COMBINED_compressed.pdf'>Download PDF</a>
                        </iframe>
                    </Col>
                </Row>
            </Content>
        </Layout>

    )
}

export default withRouter(Controller);