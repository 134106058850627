import { useState, useContext, useEffect } from 'react'
import {Link} from 'react-router-dom'
import DND from './Components/DND'
import { materialityAPI } from '../../Components/Helpers'
import { FormContext } from "../../Context/FormContext"
import { UserContext } from "../../Context/UserContext"
import AddItems from "./Components/AddItems/AddItems";
import AddItemsExternal from "./Components/AddItems/AddItemsExternal"
import AddItemsInternal from "./Components/AddItems/AddItemsInternal"
import { v4 as uuidv4 } from 'uuid';

//styling
import { Layout, Typography, Input, Row, Col, Divider, Button, message, Modal, Tabs, Select } from 'antd'
import { SaveOutlined, UploadOutlined, ReloadOutlined, ClearOutlined } from '@ant-design/icons'
const { Content} = Layout
const {Text, Title} = Typography
const { TabPane } = Tabs
const { Option } = Select

const Form = (props:any) => {
    const [formItem, setFormItem] = useState<any | null>({});
    const [formName, setFormName] = useState<any>("")
    const [formId, setFormId] = useState<any>("")
    const [formItemIndex, setFormItemIndex] = useState<any>("")
    const [saveLoading, setSaveLoading] = useState<boolean>(false)
    const [errorLoading, setErrorLoading] = useState<boolean>(false)
    const [errorLoadingMessage, setErrorLoadingMessage] = useState<any>("")
    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
    const [templateModalVisible, setTemplateModalVisible] = useState<boolean>(false)
    const [clearModalVisible, setClearModalVisible] = useState<boolean>(false)
    const [saveTemplateModalVisible, setSaveTemplateModalVisible] = useState<boolean>(false)
    const [showError, setError] = useState(false)
    const [formLoaded, setFormLoaded] = useState(false)
    const [unsaved, setUnsaved] = useState(false)
    const [currentTab, setCurrentTab] = useState<any>("")
    const FormState = useContext(FormContext)
    const UserState = useContext(UserContext)
    const [internalTemplate, setInternalTemplate] = useState<any>("")
    const [formTemplates, setFormTemplates] = useState<any | null>([])
    const [templateName, setTemplateName] = useState<any>("")

    useEffect(() => {   //fired on load, get form from db
        ///clear context
        handleLoadTemplates()
        setFormId(props.id)
    }, [props.id]);

    const info = (msg:any) => {
        message.info(msg)
    };

    const errorMessage = (msg:any) => {
        message.error(msg)
    };

    const handleSaveFormItems = async (items:any) => {
        let payload:any = {
            owner: UserState?.user._id,
            name: formName,
            data: []
        }

        if (props.string.toLowerCase() === "internal") {
            payload["data"] = FormState?.formItemsInternal
        }
        if (props.string.toLowerCase() === "external") {
            payload["data"] = FormState?.formItemsExternal
        }

        handleSaveForm(payload, "ITEMS")
    }

    const handleSaveFormName = async() => {
        let payload = {
            owner: UserState?.user._id,
            name: formName
        }
        handleSaveForm(payload, "NAME")
    }

    const saveAsTemplate = async() => {
        const id = uuidv4()
        const first = id.split("-")
        const templateId = first[0]
        setTemplateName(templateId)
        setSaveTemplateModalVisible(!saveTemplateModalVisible)
    }

    const handleSaveTemplate = async() => {
        let payload = {
            owner: UserState?.user._id,
            name: templateName,
            type: "internal",
            data: []
        }
        
        if (props.string.toLowerCase() === "internal") {
            payload["data"] = FormState?.formItemsInternal
        }
        if (props.string.toLowerCase() === "external") {
            payload["data"] = FormState?.formItemsExternal
        }
        try {
            const res = await materialityAPI.post('/api/template', payload)
            setSaveLoading(false)
            info("Template saved.")
            setUnsaved(false)
            setSaveTemplateModalVisible(!saveTemplateModalVisible)
            handleLoadTemplates()
        }
        catch(error) {
            console.log(error)
            setSaveLoading(false)
            errorMessage("Error saving template.")
            setSaveTemplateModalVisible(!saveTemplateModalVisible)
            //setErrorMessage(error.response.statusText + '. ' + error.response.data)
        }
    }

    const handleSaveForm = async(payload:any, type: any) => {
        console.log('Form.tsx: Handle Save Form');
        try {
            setSaveLoading(true)
            const res = await materialityAPI.put('/api/form/'+formId, payload)
            setSaveLoading(false)
            info("Form saved.")

            setUnsaved(false)
        }
        catch(error) {
            console.log(error)
            setSaveLoading(false)
            errorMessage("Error saving questionnaire.")
            //setErrorMessage(error.response.statusText + '. ' + error.response.data)
        }
    }

    const handleTab = async(key:string) => {
        console.log(key)
        setCurrentTab(key)
    }

    /**
     * Template Loading Functions
     */

    const handleLoadTemplates = async() => {
        try {
            const res = await materialityAPI.get('/api/template/all/'+UserState?.user._id)
            setFormTemplates(res.data.row)
        }
        catch (err) {
            console.log(err)
            errorMessage("Error loading templates.")
        }
    }

    const handleLoadTemplate = async() => {
        // replace current template with one selected
        // create new form id
        // get template
        setFormId("")
        const template = formTemplates.find((v:any, i:any) => v._id === internalTemplate)
        setTemplateModalVisible(!templateModalVisible)
        if (template.data) {
            try {
                let payload = {
                    data: template.data
                }
                const newInternalForm = await materialityAPI.put('/api/form/'+formId, payload)
                setFormId(newInternalForm.data.row._id)
                info("Template loaded.")
            }
            catch(err) {
                console.log(err)
                errorMessage("Error loading template.")
            }
        }

    }

    const clearTemplate = async() => {
        try {
            let payload = {
                data: []
            }
            setFormId("")
            setClearModalVisible(!clearModalVisible)
            await materialityAPI.put('/api/form/'+formId, payload)
            const newInternalForm = await materialityAPI.put('/api/form/'+formId, payload)
            setFormId(newInternalForm.data.row._id)
            info("Form cleared.")

        }
        catch(err) {
            console.log(err)
            errorMessage("Error clearing.")
        }
    }

    const templateOptions = formTemplates.map((item:any, index:any) => {
        return <Option value={item._id}>{item.name}</Option>
    })
    
    return (
        <Row>
            <Modal
                title="Load Template"
                visible={templateModalVisible}
                onOk={() => handleLoadTemplate()}
                onCancel={()=> setTemplateModalVisible(!templateModalVisible)}
            >
                <Text><em>Notice: this will overwrite the current form!</em></Text>
                <Select placeholder="List" value={internalTemplate} onChange={(e)=> setInternalTemplate(e)} style={{ width: 300, marginTop: 20 }} >
                    {templateOptions}
                </Select>
            </Modal>
            <Modal
                title="Save As Template"
                visible={saveTemplateModalVisible}
                onOk={() => handleSaveTemplate()}
                onCancel={()=> setSaveTemplateModalVisible(!saveTemplateModalVisible)}
            >
                <Text><em>Save this form as a template named</em></Text>
                <Input onChange={(e:any)=>setTemplateName(e.target.value)} value={templateName}/>         
            </Modal>
            <Modal
                title="Clear Questionnaire"
                visible={clearModalVisible}
                onOk={() => clearTemplate()}
                onCancel={()=> setClearModalVisible(!clearModalVisible)}
            >
                <Text><em>Notice: this will delete the form contents and is irreversible!</em></Text>
            </Modal>
            <Col span={10}>
                <DND 
                    id={formItem.id}
                    text={formItem.text} 
                    choices={formItem.choices} 
                    field={formItem.field} 
                    question={formItem.question}
                    tag={formItem.tag}
                    form_id={formId}
                    tab={props.string}
                    apiUrl="/api/form/"
                    saveForm={(items)=> handleSaveFormItems(items)}
                />
            </Col>
            <Col span={2}></Col>
            <Col span={6}>
                <AddItems setFormItem={(e:any) => setFormItem(e)}/>            
                {/* {props.string === "Internal" ? 
                    <AddItemsInternal setFormItem={(e:any) => setFormItem(e)}/>
                : ""}
                {props.string === "External" ? 
                    <AddItemsExternal setFormItem={(e:any) => setFormItem(e)}/>
                : ""} */}
                <Divider/>
                <Button
                    className="actionBox save"
                    type="primary"
                    icon={<SaveOutlined />}
                    loading={saveLoading}
                    onClick={(e:any)=>handleSaveFormItems(e)}
                    style={{marginTop: 0}}
                >
                    Save {props.string}
                </Button>
                <br/>
                <Button
                    className="actionBox launch"
                    type="primary"
                    icon={<UploadOutlined />}
                    style={{width: 200}}
                    href={"/questionnaire/preview/"+formId}
                    target="_blank"
                    >
                    Preview {props.string}
                </Button>
                <br/>
                <Button
                    className="actionBox launch"
                    type="primary"
                    icon={<ReloadOutlined />}
                    style={{width: 200, marginTop:10}}
                    onClick={()=> setTemplateModalVisible(!templateModalVisible)}
                    >
                    Load Template
                </Button>
                <br/>
                <Button
                    className="actionBox launch"
                    type="primary"
                    icon={<SaveOutlined />}
                    style={{width: 200, marginTop:10}}
                    onClick={()=> saveAsTemplate()}
                    >
                    Save As New Template
                </Button>
                <br/>
                <Button
                    className="actionBox launch"
                    type="primary"
                    icon={<ClearOutlined />}
                    style={{width: 200, marginTop:10}}
                    onClick={()=> setClearModalVisible(!clearModalVisible)}
                    >
                    Clear
                </Button>

            </Col>
        </Row>

    )
}

export default Form;