import {Fragment} from 'react';
import {Link, withRouter} from 'react-router-dom';

import { Result, Button } from 'antd';
import logo from '../../Images/greenworks_logo_black.svg'
import GreenWorksTag from '../../Components/GreenWorksTag'

const FeedbackComplete = (props:any) => {
    
    return (
        <Fragment>
            <Result
                status="success"
                title="Thank you for participating."
                subTitle="Your results have been submitted."
                extra={[
                
                ]}
            />
            
            <GreenWorksTag/>

            
        </Fragment>

    )
}

export default withRouter(FeedbackComplete);