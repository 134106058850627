import { v4 as uuidv4 } from 'uuid';
//styling
import { Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
const { Title } = Typography;


const AddItems = (props:any) => {

    const handleNewItem = (type:string) => { 

        type IChoices = {
            option: string,
            value: string | number
        }
        let question = ""
        let choices
        let field = ""
        if (type) {
            switch(type) {
                case "Ranking":
                    choices = [
                        {option: "Emissions", value: 0},
                        {option: "Waste reduction", value: 1},
                        {option: "Water management", value: 2},
                        {option: "Energy consumption", value: 3},
                        {option: "Land preservation", value: 4}
                    ]
                    break;
                case "RankingInternal":
                    choices = [
                        {option: "Not relevant", value: 0},
                        {option: "Of little importance", value: 1},
                        {option: "Somewhat important", value: 2},
                        {option: "Important", value: 3},
                        {option: "Very important", value: 4},
                        {option: "Crucial", value: 5}
                    ]
                    break;
                case "Multiple Choice":
                    choices = [
                        {option: "Extremely important", value: 0},
                        {option: "Very important", value: 1},
                        {option: "Somewhat important", value: 2},
                        {option: "Not so important", value: 3},
                        {option: "Not at all Important", value: 4}
                    ]
                    break;
            }
            console.log(choices)
            const id = uuidv4()
            const strippedId = id.replace(/-/g, "")  //remove dashes
            props.setFormItem({id: strippedId, text: type, question: question, choices: choices, field: field})
        }
    }
    
    return (
        <div className="formAction__Wrap">

            <div className="btn-form-item" onClick={()=> handleNewItem('Heading')}>
                <PlusOutlined style={{ fontSize: '14px' }}/>
                <Title className="actionBox__title" level={5}>Heading</Title>
            </div>
            <div className="btn-form-item" onClick={()=> handleNewItem('Paragraph')}>
                <PlusOutlined style={{ fontSize: '14px' }}/>
                <Title className="actionBox__title" level={5}>Paragraph</Title>
            </div>

            <div className="btn-form-item" onClick={()=> handleNewItem('Subject')}>
                <PlusOutlined style={{ fontSize: '14px' }}/>
                <Title className="actionBox__title" level={5}>Subject</Title>
            </div>
            <div className="btn-form-item" onClick={()=> handleNewItem('Aspect')}>
                <PlusOutlined style={{ fontSize: '14px' }}/>
                <Title className="actionBox__title" level={5}>Aspect</Title>
            </div>
            <div className="btn-form-item" onClick={()=> handleNewItem('Ranking')}>
                <PlusOutlined style={{ fontSize: '14px' }}/>
                <Title className="actionBox__title" level={5}>Ranking</Title>
            </div>
            <div className="btn-form-item" onClick={()=> handleNewItem('RankingInternal')}>
                <PlusOutlined style={{ fontSize: '14px' }}/>
                <Title className="actionBox__title" level={5}>Ranking Internal</Title>
            </div>
            <div className="btn-form-item" onClick={()=> handleNewItem('Multiple Choice')}>
                <PlusOutlined style={{ fontSize: '14px' }}/>
                <Title className="actionBox__title" level={5}>Multiple Choice</Title>
            </div>
            <div className="btn-form-item" onClick={()=> handleNewItem('Longform')}>
                <PlusOutlined style={{ fontSize: '14px' }}/>
                <Title className="actionBox__title" level={5}>Longform</Title>
            </div>

        </div>
    )
}

export default AddItems;