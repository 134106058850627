import {Fragment, useState, useEffect} from 'react';
import {Link, withRouter} from 'react-router-dom';
import { materialityAPI } from '../../Components/Helpers';

import View from './Components/View';
import DataTable from './Components/DataTable';

const Controller = (props:any) => {

    const [campaignName, setCampaignName] = useState("")
    
    useEffect(()=> {
        const getCampaign = async() => {
            const res = await materialityAPI.get('/api/campaign/'+props.match.params.filter2)
            const { name} = res.data.row

            setCampaignName(name)
        }

        getCampaign()
    }, [])

    let component = null;
    console.log(props.match.params.filter1, props.match.params.filter2, props.match.params.filter3)
    switch(props.match.params.filter1) {
        case "view":
            component = <View campaign_id={props.match.params.filter2}/>
            break;
        case "data":
            component = <DataTable campaign_id={props.match.params.filter2} stakeholder_definition={props.match.params.filter3} name={campaignName}/>
            break;
    }
    
    return (
        <Fragment>
            {component}
        </Fragment>

    )
}

export default withRouter(Controller);