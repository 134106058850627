import {Fragment, useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
//styling
import { Typography, Radio, Space, Row, Divider, InputNumber } from 'antd';
const { Title, Text } = Typography;


const PreviewImportance = (props:any) => {
    
    const [choices, setChoices] = useState([])

    useEffect(()=> {
        console.log(props)

        setChoices(props.choices)

    }, [])

    const radio = choices.map((item:any, index:any) => {
        return  <Space>
                    <Radio key={"radio-"+index} style={{paddingLeft:20}} value={item.option}>{item.option}</Radio>
                </Space>
    })
    
    return (
        <Fragment>
            <Row style={{marginBottom:20}}>
                <Text strong>{props.number}. {props.question}</Text>
            </Row>
            <Row>
            <Radio.Group>
                <Space direction="vertical">
                    {radio}
                </Space>
            </Radio.Group>
            </Row>
            <Divider/>
        </Fragment>
    )
}

export default PreviewImportance