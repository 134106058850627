import { Fragment, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { materialityAPI } from '../../../Components/Helpers';
import { v4 as uuidv4 } from 'uuid';
import { Input, Form, Select } from 'antd';

import { FormInstance } from 'antd/lib/form';
import { StakeholderTypeOptions } from '../../../constants';
//styling
import { Layout, Typography, Modal, Row, Col, Divider, List, message, Drawer, Table, Alert, Tabs } from 'antd';
import { ColumnsType } from 'antd/es/table'
import { SaveOutlined } from '@ant-design/icons'
import AddContactResultForm from './AddContactResultForm';
const { Content } = Layout
const { Text, Title } = Typography
const { TabPane } = Tabs

interface ICampaign {
    key: number;
    name: string;
    email: string;
    _id: string;
}

const Results = (props: any) => {

    const [addContactForm] = Form.useForm();
    const [campaignResults, setCampaignResults] = useState<any | null>([])
    const [campaignName, setCampaignName] = useState("")
    const [formResultsFormatted, setFormResultsFormatted] = useState<any | null>([])
    const [resendOutEmails, setResendOutEmails] = useState<any | null>([])
    const [viewResultName, setViewResultName] = useState("")
    const [viewResultEmail, setViewResultEmail] = useState("")
    const [viewResultLink, setViewResultLink] = useState("")
    const [viewResultFormat, setViewResultFormat] = useState<any | null>([])
    const [viewResult, setViewResult] = useState<boolean>(false)
    const [externalTable, setExternalTable] = useState<any | null>([])
    const [internalTable, setInternalTable] = useState<any | null>([])
    const [resendInternalModalVisible, setInternalResendModalVisible] = useState<boolean>(false)
    const [resendExternalModalVisible, setExternalResendModalVisible] = useState<boolean>(false)
    const [addInternalContactModalVisible, setAddInternalContactModalVisible] = useState<boolean>(false);
    const [addExternalContactModalVisible, setAddExternalContactModalVisible] = useState<boolean>(false);
    const [externalEmailList, setExternalEmailList] = useState<any | null>([])
    const [internalEmailList, setInternalEmailList] = useState<any | null>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<any | null>([])
    const [internalSelectedRowKeys, setInternalSelectedRowKeys] = useState<any | null>([])
    const [reloadKey, setReloadKey] = useState("")
    const [internalStatus, setInternalStatus] = useState("")
    const [externalStatus, setExternalStatus] = useState("")

    useEffect(() => {
        getCampaign()
    }, [props.campaign_id])

    useEffect(() => {
        getCampaign()
    }, [reloadKey])

    const getCampaign = async () => {
        const res = await materialityAPI.get('/api/campaign/' + props.campaign_id)
        const { campaigns, name } = res.data.row

        const internalCampaign = campaigns.find((v: any) => v.type === "internal")
        const externalCampaign = campaigns.find((v: any) => v.type === "external")

        const resInternal = await materialityAPI.get('/api/campaignsingle/' + internalCampaign.id)
        const resExternal = await materialityAPI.get('/api/campaignsingle/' + externalCampaign.id)

        if (resInternal.data.row.campaignResults) {
            if (Object.keys(resInternal.data.row.campaignResults).length > 0) {
                let count = 0
                for (let x in resInternal.data.row.campaignResults) {
                    count++
                    resInternal.data.row.campaignResults[x]["key"] = count
                }
                setInternalTable(Object.values(resInternal.data.row.campaignResults))
            }
        }
        if (resExternal.data.row.campaignResults) {
            if (Object.keys(resExternal.data.row.campaignResults).length > 0) {
                let count = 0
                for (let x in resExternal.data.row.campaignResults) {
                    count++
                    resExternal.data.row.campaignResults[x]["key"] = count
                }
                setExternalTable(Object.values(resExternal.data.row.campaignResults))
            }
        }
        setCampaignName(name)
        if (resInternal.data.row.campaignState) {
            setInternalStatus(resInternal.data.row.campaignState)
        }
        if (resExternal.data.row.campaignState) {
            setExternalStatus(resExternal.data.row.campaignState)
        }

    }

    const info = (msg: any) => {
        message.info(msg)
    };

    const errorMessage = (msg: any) => {
        message.error(msg)
    };

    const handleResultsTab = async (key: string) => {
        console.log(key)
        try {
            const res = await materialityAPI.get('/api/campaign/' + props.campaign_id)
            const { campaignResults } = res.data.row
            setCampaignResults(campaignResults)


        }
        catch (err) {
            console.log(err)
        }
    }

    const handleResultView = async (record: any) => {
        setViewResult(true)
        console.log(record)
        setViewResultName(`${record.memberFirstName} ${record.memberLastName}`)
        setViewResultEmail(record.memberEmail)
        setViewResultLink(record.link)
        setFormResultsFormatted(record.formResultFormatted)
    }

    const columnsInternal: ColumnsType<ICampaign> = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'First Name',
            dataIndex: 'memberFirstName',
            key: 'memberFirstName',
            filterMultiple: false,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Last Name',
            dataIndex: 'memberLastName',
            key: 'memberLastName',
            filterMultiple: false,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Type of Stakeholder',
            dataIndex: 'memberStakeholderType',
            key: 'memberStakeholderType',
            filterMultiple: false,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Email',
            dataIndex: 'memberEmail',
            key: 'memberEmail',
        },
        {
            title: 'Email Sent',
            dataIndex: 'emailSentDate',
            key: 'emailSentDate',
        },
        {
            title: 'Completed Date',
            dataIndex: 'completedDate',
            key: 'completedDate',
        },
        {
            title: 'Result',
            dataIndex: 'result',
            key: 'view',
            render: (text, record, index) => <a onClick={() => handleResultView(record)} style={{ color: "#1DA57A" }}>View</a>
        }
    ];

    const columnsExternal: ColumnsType<ICampaign> = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'First Name',
            dataIndex: 'memberFirstName',
            key: 'memberFirstName',
            filterMultiple: false,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Last Name',
            dataIndex: 'memberLastName',
            key: 'memberLastName',
            filterMultiple: false,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Type of Stakeholder',
            dataIndex: 'memberStakeholderType',
            key: 'memberStakeholderType',
            filterMultiple: false,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Email',
            dataIndex: 'memberEmail',
            key: 'memberEmail',
        },
        {
            title: 'Email Sent',
            dataIndex: 'emailSentDate',
            key: 'emailSentDate',
        },
        {
            title: 'Completed Date',
            dataIndex: 'completedDate',
            key: 'completedDate',
        },
        {
            title: 'Result',
            dataIndex: 'result',
            key: 'view',
            render: (text, record, index) => <a onClick={() => handleResultView(record)} style={{ color: "#1DA57A" }}>View</a>
        }
    ];

    let formResults = null
    if (formResultsFormatted) {
        formResults = formResultsFormatted.map((item: any, index: any) => {
            return <Row key={`${index}-result`} style={{ marginTop: 10, marginBottom: 10, borderBottom: '1x solid grey' }}>
                <Col span={12}><span style={{ color: 'rgba(0, 0, 0, 0.95)' }}>{item.question}</span></Col>
                <Col span={12}>
                    <ul className="formResultsList">
                        <Row>
                            {item.answers.map((answersitem: any, answersindex: any) => {
                                return (
                                    <li key={`${index}-${answersindex}-answer`} style={{ width: '100%', display: 'flex' }}>
                                        <Col span={18}>{answersitem.answer}</Col>
                                        <Col span={6}>[{answersitem.score}]</Col>
                                    </li>
                                );
                            })}
                        </Row>
                    </ul>
                </Col>
            </Row>
        })
    }

    const resendEmails = async (string: string) => {
        let emails: any = []
        try {
            if (string === "internal") {
                emails = internalEmailList
            }
            if (string === "external") {
                emails = externalEmailList
            }
            const res = await materialityAPI.post('/api/campaignsingle/resend/' + props.campaign_id + '/' + string, emails)
            console.log(res)
            const id = uuidv4()
            setReloadKey(id)
            if (string === "internal") {
                setInternalResendModalVisible(!resendInternalModalVisible)
            }
            if (string === "external") {
                setExternalResendModalVisible(!resendExternalModalVisible)
            }
            info('Re-sent emails.')
        }
        catch (error) {
            console.log(error)
            errorMessage("Error re-sending email(s).")
        }

    }

    const onSelectChange = (selectedRowKeys: any, selectedRows: any) => {
        setSelectedRowKeys(selectedRowKeys)
        setExternalEmailList(selectedRows)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    const onSelectChangeInternal = (selectedRowKeys: any, selectedRows: any) => {
        setInternalSelectedRowKeys(selectedRowKeys)
        setInternalEmailList(selectedRows)
    }

    const rowSelectionInternal = {
        internalSelectedRowKeys,
        onChange: onSelectChangeInternal,
    }

    const showAddInternalContactModal = () => {
        addContactForm.resetFields();
        setAddInternalContactModalVisible(true);
    }

    const onCancelAddInternalContact = () => {
        addContactForm.resetFields();
        setAddInternalContactModalVisible(false);
    }

    const showAddExternalContactModal = () => {
        addContactForm.resetFields();
        setAddExternalContactModalVisible(true);
    }

    const onCancelAddExternalContact = () => {
        addContactForm.resetFields();
        setAddExternalContactModalVisible(false);
    }

    const onAddContact = (isInternal: boolean) => {
        addContactForm.validateFields()
            .then(async values => {
                try {
                    const campaignType = isInternal ? 'internal' : 'external';
                    const res = await materialityAPI.post('/api/campaignsingle/addcontact/' + props.campaign_id + "/" + campaignType, values)

                    if (isInternal) {
                        onCancelAddInternalContact();
                    } else {
                        onCancelAddExternalContact();
                    }
                    const id = uuidv4()
                    setReloadKey(id)
                    info('Contact added to campaign.');
                }
                catch (err) {
                    console.log(err);
                }


            })
    }


    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Modal
                title={"Add Internal Contact"}
                visible={addInternalContactModalVisible}
                onOk={() => onAddContact(true)}
                onCancel={() => onCancelAddInternalContact()}
            >
                <AddContactResultForm form={addContactForm} />
            </Modal>
            
            <Modal
                title={"Add External Contact"}
                visible={addExternalContactModalVisible}
                onOk={() => onAddContact(false)}
                onCancel={() => onCancelAddExternalContact()}
            >
                <AddContactResultForm form={addContactForm} />
            </Modal>

            <Modal
                title={"Resend Internal Emails"}
                visible={resendInternalModalVisible}

                onOk={() => resendEmails("internal")}
                onCancel={() => setInternalResendModalVisible(!resendInternalModalVisible)}
            >
                <p>Are you sure you want to resend internal emails for:</p>
                <List
                    bordered
                    size="small"
                    pagination={{
                        pageSize: 10
                    }}
                    dataSource={internalEmailList}
                    renderItem={(item: any) => (
                        <List.Item>
                            {item.memberEmail}
                        </List.Item>
                    )}
                />
            </Modal>
            <Modal
                title={"Resend External Emails"}
                visible={resendExternalModalVisible}
                onOk={() => resendEmails("external")}
                onCancel={() => setExternalResendModalVisible(!resendExternalModalVisible)}
            >
                <p>Are you sure you want to resend external emails for:</p>
                <List
                    bordered
                    size="small"
                    pagination={{
                        pageSize: 10
                    }}
                    dataSource={externalEmailList}
                    renderItem={(item: any) => (
                        <List.Item>
                            {item.memberEmail}
                        </List.Item>
                    )}
                />
            </Modal>

            <Drawer
                width={760}
                placement="right"
                closable={false}
                onClose={() => setViewResult(!viewResult)}
                visible={viewResult}
            >
                <p><strong>Name: </strong>{viewResultName}</p>
                <p><strong>Email: </strong>{viewResultEmail}</p>
                <p><strong>Link: </strong><a target="_blank" href={viewResultLink}>{viewResultLink}</a></p>
                <p><strong>Results: </strong></p>
                <Row>
                    <li style={{ width: '100%', display: 'flex', borderBottom: '1px solid #eee', borderTop: '1px solid #eee' }}>
                        <Col span={12}><strong>Question</strong></Col>
                        <Col span={6}><span style={{ paddingLeft: 40 }}><strong>Answer</strong></span></Col>
                        <Col span={6}><span style={{ paddingLeft: 40 }}><strong>Weight</strong></span></Col>
                    </li>
                </Row>
                {formResults}
            </Drawer>
            <Content style={{ padding: 40 }}>
                <Row style={{ alignItems: "center" }}>
                    <Col span={16}>
                        <Title level={5}><Link style={{ color: '#1DA57A' }} to="/results">Results</Link> &gt; {campaignName}</Title>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={2}></Col>
                </Row>
                <Divider />
                <Row>
                    <Tabs onTabClick={handleResultsTab} style={{ width: '100%' }} defaultActiveKey="internal">
                        <TabPane tab="Internal" key="internal">
                            <Row justify="end">
                                <div style={{ marginBottom: 20, backgroundColor: 'transparent', borderColor: 'transparent', color: 'black' }} className="actionBox">
                                    <Title style={{ margin: 0, color: 'black' }} className="actionBox__title" level={5}>Status: <strong>{internalStatus}</strong></Title>
                                </div>
                                <div onClick={() => showAddInternalContactModal()} style={{ marginBottom: 20, marginLeft: 20 }} className="actionBox">
                                    <Title style={{ margin: 0 }} className="actionBox__title" level={5}>Add Internal Contact</Title>
                                </div>
                                <div onClick={() => setInternalResendModalVisible(!resendInternalModalVisible)} style={{ marginBottom: 20, marginLeft: 20 }} className="actionBox">
                                    <Title style={{ margin: 0 }} className="actionBox__title" level={5}>Resend Email(s)</Title>
                                </div>
                                <div style={{ marginBottom: 20, marginLeft: 20 }} className="actionBox">
                                    <Title style={{ margin: 0 }} className="actionBox__title" level={5}>View Internal Data</Title>
                                    <Link to={`/result/data/${props.campaign_id}/internal`}></Link>
                                </div>
                            </Row>
                            <Row>
                                <Table rowSelection={rowSelectionInternal} style={{ width: '100%' }} pagination={{ pageSize: 30 }} dataSource={internalTable} columns={columnsInternal} />
                            </Row>
                        </TabPane>
                        <TabPane tab="External" key="external">
                            <Row justify="end">
                                <div style={{ marginBottom: 20, backgroundColor: 'transparent', borderColor: 'transparent', color: 'black' }} className="actionBox">
                                    <Title style={{ margin: 0, color: 'black' }} className="actionBox__title" level={5}>Status: <strong>{externalStatus}</strong></Title>
                                </div>
                                <div onClick={() => showAddExternalContactModal()} style={{ marginBottom: 20, marginLeft: 20 }} className="actionBox">
                                    <Title style={{ margin: 0 }} className="actionBox__title" level={5}>Add External Contact</Title>
                                </div>
                                <div onClick={() => setExternalResendModalVisible(!resendExternalModalVisible)} style={{ marginBottom: 20, marginLeft: 20 }} className="actionBox">
                                    <Title style={{ margin: 0 }} className="actionBox__title" level={5}>Resend Email(s)</Title>
                                </div>
                                <div style={{ marginBottom: 20, marginLeft: 20 }} className="actionBox">
                                    <Title style={{ margin: 0 }} className="actionBox__title" level={5}>View External Data</Title>
                                    <Link to={`/result/data/${props.campaign_id}/external`}></Link>
                                </div>
                            </Row>
                            <Row>
                                <Table rowSelection={rowSelection} style={{ width: '100%' }} pagination={{ pageSize: 30 }} dataSource={externalTable} columns={columnsExternal} />
                            </Row>
                        </TabPane>
                    </Tabs>
                </Row>
            </Content>

        </Layout>

    )
}

export default Results;