import { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import DND from './Components/DND';
import { materialityAPI } from '../../Components/Helpers';
import { FormContext } from "../../Context/FormContext";
import { UserContext } from "../../Context/UserContext";

//styling
import { Layout, Typography, Input, Row, Col, Divider, Button, message } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
const { Content} = Layout;
const { Title, Text} = Typography;

type TNewItem = {
    id: string, text: string;
}

const NewQuestionnaire = (props:any) => {

    const [formName, setFormName] = useState<any>("")
    const [saveLoading, setSaveLoading] = useState<boolean>(false)
    const FormState = useContext(FormContext);
    const UserState = useContext(UserContext);

    useEffect(() => {   //fired on load, reload from context
        console.log(FormState)
        if (FormState) {
            //setFormName(FormState.name);
        }
    }, []);

    useEffect(() => {   //fired when form name changed
        //FormState?.setFormName(formName);
    }, [formName]);

    const info = (msg:any) => {
        message.info(msg)
    };

    const errorMessage = (msg:any) => {
        message.error(msg)
    };

    const handleSaveForm = async () => {
        setSaveLoading(true)
        let internalPayload = {
            owner: UserState?.user._id,
            name: `[Internal] ${formName}`,
            type: "internal"
        }
        let externalPayload = {
            owner: UserState?.user._id,
            name: `[External] ${formName}`,
            type: "external"
        }
        try {
            const internalForm = await materialityAPI.post('/api/form', internalPayload)
            const externalForm = await materialityAPI.post('/api/form', externalPayload)

            let questionnairePayload = {
                owner: UserState?.user._id,
                name: formName,
                forms: [{id: internalForm.data.newForm._id, type: "internal"}, {id: externalForm.data.newForm._id, type: "external"}]
            }

            const res = await materialityAPI.post('/api/questionnaire', questionnairePayload)
            console.log(res)
            setSaveLoading(false)
            info("Form saved.")
            // redirect to edit page
            props.history.push("/questionnaire/edit/"+res.data.newQuestionnaire._id)
        }
        catch(error) {
            console.log(error)
            setSaveLoading(false)
            errorMessage("Error saving questionnaire.")
            //setErrorMessage(error.response.statusText + '. ' + error.response.data)
        }
    }

    return (
        <Layout style={{height: "100vh"}}>
            <Content style={{paddingLeft: 40, paddingTop: 40}}>
                <Row style={{alignItems: "center"}}>
                    <Col span={8}>
                        <Text style={{paddingBottom: 10}}>Enter the name of the form:</Text>
                        <Input style={{marginTop: 10}} value={formName} onChange={(e)=> setFormName(e.target.value)} placeholder="Questionnaire Name" />
                    </Col>
                    <Col span={2}></Col>
                    <Col span={2}></Col>
                    <Col style={{borderLeft: '1px solid #f0f0f0', paddingLeft: 20}} span={12}>
                        <Button
                            className="actionBox save"
                            type="primary"
                            icon={<SaveOutlined />}
                            loading={saveLoading}
                            onClick={handleSaveForm}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
                <Divider/>  
                <Row>
                  <Col span={8}>

                  </Col>
                  <Col span={2}></Col>
                  <Col span={2}></Col>
                  <Col style={{borderLeft: '1px solid #f0f0f0', paddingLeft: 20}} span={12}>

                  </Col>
                </Row>
            </Content>
        </Layout>
    )
}

export default withRouter(NewQuestionnaire);