import {Fragment, useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
//styling
import { Typography, Radio, Space, Row, Divider, Select, InputNumber } from 'antd';
const { Title, Text } = Typography;
const { Option } = Select;


const PreviewRanking = (props:any) => {
    
    const [choices, setChoices] = useState([])

    useEffect(()=> {
        console.log(props)

        setChoices(props.choices)

    }, [])

    const handleChangeRanking= (e:any) => {
        console.log(e)
    }

    const options = choices.map((item:any, index:any) => {
        return  <Space>
                    <InputNumber style={{width:60}} min={1} max={10} /><Text>{item.option}</Text>
                </Space>
    })
    
    return (
        <Fragment>
            <Row style={{marginBottom:20}}>
                <Text strong>{props.number}. {props.question}</Text>
            </Row>
            <Row>
                <Space direction="vertical" style={{marginLeft:25}}>
                    {options}
                </Space>
            </Row>
            <Divider/>
        </Fragment>
    )
}

export default PreviewRanking