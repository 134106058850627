import { Fragment, useState } from 'react';
import { StakeholderTypes } from '../../../constants';

//styling
import { Input, Form, Select, FormInstance } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';

const { Option } = Select

export interface AddContactResultFormItems {

}

interface AddContactResultFormProps extends AddContactResultFormItems {
    form: FormInstance<any>
}



const AddContactResultForm = (props: AddContactResultFormProps) => {
    const { form } = props;
    const stakeholderTypeOptions = StakeholderTypes.map((item: any, index: any) => {
        return <Option value={item}>{item}</Option>
    });

    return (
        <Fragment>
            <b style={{ color: 'red'}}>Contacts with the same email will have their record and survey results replaced.</b><br/><br/>

            <Form
                form={form}
                labelCol={{ span: 6 }}>
                <Form.Item label="First Name" name="firstName" style={{ width: '100%', marginBottom: '5px' }} rules={[{ required: true }]} >
                    <Input placeholder="First Name" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="Last Name" name="lastName" style={{ width: '100%', marginBottom: '5px' }} rules={[{ required: true }]} >
                    <Input placeholder="Last Name" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="Email" name="email" style={{ width: '100%', marginBottom: '5px' }} rules={[{ type: 'email', required: true }]} >
                    <Input placeholder="Email" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="Stakeholder Type" name="stakeholderType" style={{ width: '100%', marginBottom: '5px' }}
                    rules={[{ required: true }]}>
                    <Select placeholder="Type of Stakeholder">
                        {stakeholderTypeOptions}
                    </Select>
                </Form.Item>
            </Form>
        </Fragment>
    );

};

export default AddContactResultForm;