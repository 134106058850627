import { useContext, useEffect, useState } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { getTokenSourceMapRange } from 'typescript';
import {UserContext} from '../Context/UserContext.tsx'
import { materialityAPI } from './Helpers.tsx'

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const userState = useContext(UserContext)
  const { authenticated } = userState

  const [isLoading, setLoading] = useState(true)
  const [isAuthorized, setAuth] = useState(false)

  useEffect(()=> {
    const verifyAuth = async(token) => {
      console.log(token, authenticated)
      console.log(token === undefined)
      if (token && !authenticated) {
        console.log('re-authenticating token...')
        console.log(token)
        try {
          const res = await materialityAPI.post("/auth/verify", {"token": token});
          console.log(res)
          if (res.data.newToken) {
            const user_ = res.data.user_
            userState?.setUser({
                email: user_.email,
                role: user_.role,
                firstName: user_.firstName,
                lastName: user_.lastName,
                company: user_.company,
                _id: user_._id,
                token: res.data.newToken
            });
            userState?.setAuthenticated(true)
            localStorage.setItem('materiality.Token', res.data.newToken);
            setAuth(true)
            setLoading(false)
          }
        }
        catch (error) {
          setLoading(false)
          console.log(error.message)
        }
      }
      else if (authenticated){
        console.log('user is authenticated by state')
        setAuth(true)
        setLoading(false)
      }
      else {
        console.log('user is not authenticated and token does not exist')
        setAuth(false)
        setLoading(false)
      }
    }
    verifyAuth(localStorage.getItem("materiality.Token"));
      
  }, []);
  

  return (
    
    <Route {...rest} render={
      props => {
        return isAuthorized ? 
          (<Component {...props} />) : 
          (isLoading ? 
            <div className="lds-ripple-wrap">
                  <div className="lds-ripple">
                      <div></div>
                      <div></div>
                  </div>
              </div>
          : <Redirect to={ { pathname: '/login', state: {from: props.location} }} />)
      }
    } />
  )
}

export default withRouter(ProtectedRoute);
