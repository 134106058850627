import React, { useState, createContext } from 'react';

interface INewListContext {
  name: string;
  items: any;
  setListName: (state: string) => void;
  setItems: (state: any) => void;
}

export const NewListContext = createContext<INewListContext | null>(null);

export const NewListProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [items, setItems] = useState([]);
  const [name, setListName] = useState("");
  return (
    <NewListContext.Provider value={{ setItems, setListName, name, items }}>
      {children}
    </NewListContext.Provider>
  );
};