import React, { useState, createContext } from 'react';

interface INewCampaignContext {
  name: string;
  items: any;
  setCampaignName: (state: string) => void;
  setItems: (state: any) => void;
}

export const NewCampaignContext = createContext<INewCampaignContext | null>(null);

export const NewCampaignProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [items, setItems] = useState([]);
  const [name, setCampaignName] = useState("");
  return (
    <NewCampaignContext.Provider value={{ setItems, setCampaignName, name, items }}>
      {children}
    </NewCampaignContext.Provider>
  );
};