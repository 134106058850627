import {Link} from 'react-router-dom';
import { useEffect, useContext, useState, Fragment } from 'react';
import { UserContext } from "../Context/UserContext";
import { materialityAPI } from '../Components/Helpers';
import dayjs from 'dayjs';

import { Layout, Typography, Menu, Row, Col, Divider, Table, Modal, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { PlusOutlined } from '@ant-design/icons';
const { Content, Sider } = Layout;
const { Title } = Typography;

interface IForm {
    key: number;
    name: string;
    date: string;
    _id: string;
}

const Questionnaires = () => {
    const [forms, setForms] = useState([])
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [deleteKey, setDeleteKey] = useState("")
    const [deleteFormName, setDeleteFormName] = useState("")
    const UserState = useContext(UserContext)

    useEffect(() => {
        const getAllQuestionnaires = async(user:any) => {
            try {
                const res = await materialityAPI.get('/api/questionnaire/all/'+user._id)
                const data = res.data.row.reverse()

                setForms(data)
            }
            catch(err) {
                console.log(err)
            }
        }
        const {user}:any = UserState;
        getAllQuestionnaires(user);
    }, []);

    //Delete Module
    const handleDelete = (record:any) => {
        setDeleteModalVisible(!deleteModalVisible)
        setDeleteKey(record._id)
        setDeleteFormName(record.name)
    }
    const deleteForm = async() => {
        try {
            const res = await materialityAPI.delete('/api/questionnaire/'+deleteKey)
            
            const {user}:any = UserState;
            const res_all = await materialityAPI.get('/api/questionnaire/all/'+user._id)
            const data = res_all.data.row.reverse()
            setForms(data)
            setDeleteModalVisible(!deleteModalVisible)
            message.info("Questionnaire deleted.")
        }
        catch(error) {
            console.log(error)
            message.info("Error deleting questionnaire.")
            setDeleteModalVisible(!deleteModalVisible)
        }
    }

    const info = (msg:any) => {
        message.info(msg)
    };

    const columns: ColumnsType<IForm> = [
        
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            filterMultiple: false,
            sortDirections: ['descend', 'ascend'],
            render: (text, record, index) => <Link style={{color: "#1DA57A"}} to={"/questionnaire/edit/"+record._id}>{text}</Link>
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record, index) => dayjs(text).format('YYYY-MM-DD')
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            filterMultiple: false,
            render: (text, record, index) => <a href="#" style={{color: "#1DA57A"}} onClick={() => handleDelete(record)}>Delete</a>
        },
        
    ];
    
    return (
        <Fragment>
            <Modal
                title={"Delete "+ deleteFormName}
                visible={deleteModalVisible}
                onOk={() => deleteForm()}
                onCancel={()=> setDeleteModalVisible(!deleteModalVisible)}
            >
            <p>Are you sure you want to delete?</p>
            </Modal>
        
            <Layout style={{minHeight: "80vh"}}>
                <Content style={{paddingLeft: 40, paddingTop: 40}}>
                    <Row style={{alignItems: "center"}}>
                        <Col span={20}>
                            <Title style={{marginBottom: 0}} level={5}>Questionnaires</Title>
                        </Col>
                        <Col span={4}>
                            <div className="actionBox">
                                <PlusOutlined style={{ fontSize: '14px' }}/>
                                <Title className="actionBox__title" level={5}>New Questionnaire</Title>
                                <Link to="/questionnaire/create/new"></Link>
                            </div>
                        </Col>
                    </Row>
                    <Divider/>  
                    <Row>
                        <Table style={{width: '90%'}} pagination={{ pageSize: 10 }} dataSource={forms} columns={columns}/>
                    </Row>
                </Content>
            </Layout>
        </Fragment>
    )
}

export default Questionnaires;