import { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { materialityAPI } from '../../Components/Helpers';
import { UserContext } from "../../Context/UserContext";
import { NewCampaignContext } from "../../Context/NewCampaignContext";

//styling
import { Layout, Form, Input, Row, Col, Divider, Button, message, Typography, Table, Modal, Select, Space, Switch } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { PropertySafetyTwoTone, SaveOutlined } from '@ant-design/icons'
import { AnyRecord } from 'dns';
const { Content } = Layout
const { Text } = Typography
const { Option } = Select

interface ICampaign {
    key: number;
    name: string;
    email: string;
    _id: string;
}

const NewCampaign = (props: any) => {
    const [campaign, setCampaign] = useState<any | null>([])
    const [campaignName, setCampaignName] = useState<any>("")
    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
    const [saveLoading, setSaveLoading] = useState<boolean>(false)
    const [showError, setError] = useState(false)
    const [lists, setLists] = useState<any | null>([])
    const [forms, setForms] = useState<any | null>([])
    const [campaignForm, setCampaignForm] = useState<any | null>("")
    const [campaignList, setCampaignList] = useState<any | null>("")

    const UserState = useContext(UserContext)
    const NewCampaignState = useContext(NewCampaignContext)

    useEffect(() => {   //fired on load, reload from context

        const getLists = async () => {
            const res = await materialityAPI.get('/api/contactlist/all/' + UserState?.user._id)
            setLists(res.data.row)
        }
        const getForms = async () => {
            const res = await materialityAPI.get('/api/questionnaire/all/' + UserState?.user._id)
            setForms(res.data.row)
        }
        getLists()
        getForms()

    }, []);

    const info = (msg: any) => {
        message.info(msg)
    };

    const errorMessage = (msg:any) => {
        message.error(msg)
    };

    const handleSaveCampaign = async () => {

        setSaveLoading(true)
        try {
            // get forms and lists
            const resForms = await materialityAPI.get('/api/questionnaire/'+campaignForm)
            const resLists = await materialityAPI.get('/api/contactlist/'+campaignList)
            const {forms} = resForms.data.row
            const {lists} = resLists.data.row
            const internalForm = forms.find((v:any)=> v.type === "internal")
            const externalForm = forms.find((v:any)=> v.type === "external")
            const internalList = lists.find((v:any)=> v.type === "internal")
            const externalList = lists.find((v:any)=> v.type === "external")

            // create two singles, then one campaign
            let payloadInternal = {
                owner: UserState?.user._id,
                questionnaire: campaignForm,
                name: campaignName,
                campaignType: "internal",
                form: internalForm.id,
                list: internalList.id,
                contactList: campaignList
            }
            let payloadExternal = {
                owner: UserState?.user._id,
                questionnaire: campaignForm,
                name: campaignName,
                campaignType: "external",
                form: externalForm.id,
                list: externalList.id,
                contactList: campaignList
            }


            console.log(internalForm, internalList)

            const resSingleInternal = await materialityAPI.post('/api/campaignsingle', payloadInternal)
            const resSingleExternal = await materialityAPI.post('/api/campaignsingle', payloadExternal)
            console.log(resSingleInternal)
            console.log(resSingleExternal)
            const internal_id = resSingleInternal.data.newCampaignSingle._id
            const external_id = resSingleExternal.data.newCampaignSingle._id
            let payloadCampaign = {
                owner: UserState?.user._id,
                questionnaire: campaignForm,
                contactList: campaignList,
                name: campaignName,
                campaigns: [{id: internal_id, type: "internal"},{id: external_id, type: "external"}]
            }
            const res = await materialityAPI.post('/api/campaign', payloadCampaign)
            
            console.log(res)
            setSaveLoading(false)
            info("Campaign saved.")
            // redirect to edit page
            props.history.push("/campaign/edit/" + res.data.newCampaign._id)
        }
        catch (error) {
            console.log(error)
            setSaveLoading(false)
            errorMessage("Error saving form.")
            //setErrorMessage(error.response.statusText + '. ' + error.response.data)
        }
    }

    const columns: ColumnsType<ICampaign> = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            filterMultiple: false,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'date',
        },
        {
            title: 'Result',
            dataIndex: 'result',
            key: 'view',
            render: (text, record, index) => <a href="#" style={{ color: "#1DA57A" }}>View</a>
        }

    ];

    const listOptions = lists.map((item: any, index: any) => {
        return <Option key={item._id} value={item._id}>{item.name}</Option>
    });

    const formOptions = forms.map((item: any, index: any) => {
        return <Option key={item._id} value={item._id}>{item.name}</Option>
    });

    console.log(campaignForm, campaignList)

    return (
        <Layout style={{ height: "100vh" }}>
            <Content style={{ paddingLeft: 40, paddingTop: 40 }}>
                <Row style={{ alignItems: "center" }}>
                    <Col span={8}>
                        <Input value={campaignName} onChange={(e) => setCampaignName(e.target.value)} placeholder="Campaign Name" />
                    </Col>
                    <Col span={2}></Col>
                    <Col span={2}></Col>
                    <Col style={{ borderLeft: '1px solid #f0f0f0', paddingLeft: 20 }} span={12}>
                        <Button
                            className="actionBox save"
                            type="primary"
                            icon={<SaveOutlined />}
                            loading={saveLoading}
                            onClick={handleSaveCampaign}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
                <Divider />
                <Row>

                    <Col span={12}>
                        <Space style={{width: "100%"}} size="large" direction="vertical">
                            <Row>
                                <Space direction="vertical">
                                    <Text >Choose Contacts</Text>
                                    <Select placeholder="List" onChange={(e)=> setCampaignList(e)} style={{ width: 300 }} >
                                        {listOptions}
                                    </Select>
                                </Space>
                            </Row>
                            <Row>
                                <Space direction="vertical">
                                    <Text>Choose Questionnaire</Text>
                                    <Select placeholder="Form" onChange={(e)=> setCampaignForm(e)} style={{ width: 300 }} >
                                        {formOptions}
                                    </Select>
                                </Space>
                            </Row>
                            {/* <Divider orientation="left">Results</Divider>
                            <Row>
                                <Table style={{ width: '90%' }} pagination={{ pageSize: 30 }} dataSource={campaign} columns={columns} />
                            </Row> */}
                        </Space>
                    </Col>
                    
                    <Col span={12} style={{ borderLeft: '1px solid #f0f0f0', paddingLeft: 20 }} >
                        {/* <Text>Status of Campaign</Text>
                        <Switch disabled={true} /> */}
                        {showError ?
                            <Text>{errorMessage}.</Text>
                            : ""}
                    </Col>
                </Row>
            </Content>

        </Layout>
    )
}

export default withRouter(NewCampaign);